import { useLocation } from "react-router-dom"
import { RouteStateI } from "../types"
import {useIntl} from "react-intl";

export const useTitle = () => {
  const intl = useIntl();

  const { pathname, state } = useLocation<RouteStateI>()
  let title
  let navTitle
  const isLocation = pathname.split("/")[1]
  if (isLocation === "location") {
    title = intl.formatMessage({id:'USETITLE.TITRE_MONESPACE',defaultMessage:'Mon espace'});
  }
  else {
    switch (pathname) {
      case "/moncompte":
        break
      case "/infos":
        title = intl.formatMessage({id:'USETITLE.TITRE_AJOUTETABLISS',defaultMessage:'Ajouter les informations sur votre établissement'});
        navTitle = intl.formatMessage({id:'USETITLE.NAV_TITRE_ETABLISS',defaultMessage:"Établissement"})
        break
      case "/plan":
        title = intl.formatMessage({id:'USETITLE.TITRE_SELECT_FORFAIT',defaultMessage:"Séléctionnez un forfait"});
        navTitle = intl.formatMessage({id:'USETITLE.NAV_TITRE_FORFAIT',defaultMessage:"Forfait"})
        break
      case "/sponsorisation":
        if (state?.status === "renewal") {
          title = intl.formatMessage({id:'USETITLE.TITRE_RENOUV_OFFRE',defaultMessage:"Renouvelement de l'offre: souhaitez vous adoptez l'option Emplacement sponsorisé ?"})

        } else {
          title = intl.formatMessage({id:'USETITLE.TITRE_EMPLAC_SPONSOR',defaultMessage:"Adoptez l'option Emplacement sponsorisé"})
        }
        navTitle = intl.formatMessage({id:'USETITLE.NAV_TITRE_SPONSOR',defaultMessage:"Sponsorisation"})
        break
      case "/validation":
        title = intl.formatMessage({id:'USETITLE.TITRE_VALIDATION',defaultMessage:'Validez votre demande'});
        navTitle = intl.formatMessage({id:'USETITLE.NAV_TITRE_VALIDATION',defaultMessage:"Validation"})
        break

      default:
        break
    }
  }


  return { title, navTitle }
}