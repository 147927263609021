import { useState, useEffect, useContext } from "react";
import appConfig from "./appConfig";
import React from "react";
import { User } from "./types";
import { fetchList } from "./API";
import { useQuery } from "react-query";
import { UserContextType } from "./authentication";
import { useIntl } from "react-intl";

export const useConf = ({
  authentication,
}: {
  authentication: UserContextType;
}) => {
  // vérifier qu'on est bien en dessous de l'auth
  const intl = useIntl();

  const { data, status } = useQuery<{
    results: { key_name: string; value: string }[];
  }>(
    ["app_configurations"],
    () => fetchList("app_configurations", {}, authentication.accessToken),
    {
      enabled: Boolean(authentication.accessToken),

      onError: (e: any) => {
        authentication.logOut();
      },
    }
  );
  const transformedKeys =
    data?.results?.reduce(
      (
        acc: { [key: string]: string },
        current: { key_name: string; value: string }
      ) => ({ ...acc, [current.key_name]: current.value }),
      {}
    ) || null;
  return transformedKeys;
};

export type ConfigurationContextType = { [x: string]: string };

export const ConfigurationContext =
  React.createContext<ConfigurationContextType | null>(null);

export const useConfigurationContext = () => {
  const ctx = useContext(ConfigurationContext);
  if (!ctx) {
    throw new Error(
      "Erreur : n'utilisez le contexte de configuration qu'au sein d'un provider de configuration"
    );
  }
  return ctx;
};
