import {
  Button,
  CircularProgress,
  Dialog,
  Paper,
  Typography,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { PaymentI } from "../../types";
import { uuid4 } from "./guid";
import appConfig from "../../appConfig";
import { Base64 } from "./base64-encode-decode";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import MainLayout from "../MainLayout";
import { FormattedMessage, useIntl } from "react-intl";
import { actionate, createOne } from "../../API";
import { useUserContext } from "../../authentication";

const {
  base_url,
  callback_url,
  api_key,
  api_user,
  target_env,
  ocp_qpim_subscription_key,
} = appConfig.momopay;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: 300,
      width: 300,
      padding: theme.spacing(6),
    },
    loader: {
      // marginLeft: theme.spacing(3),
      position: "absolute",
    },
  })
);
const uuid = uuid4;

const base64 = new Base64();

function MomoPay({ payment }: { payment: PaymentI | null }) {
  const intl = useIntl();
  const classes = useStyles();
  const { accessToken } = useUserContext();

  const [visible, setVisible] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState("");

  const [loading, setLoading] = useState(false);

  const [paymentRequested, setPaymentRequested] = useState(false);

  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async () => {
    setLoading(true);
    console.log("payment", payment);
    if (payment && accessToken) {
      try {
        const response = await actionate({
          action: "momopay_request",
          id: payment.id,
          params: { phone: appConfig.phonePrefix + phoneNumber },
          model: "payments",
          accessToken,
        });
        if (response.status === 200) {
          console.log(await response.json());
          setPaymentRequested(true);
        }
      } catch (error) {
        setError(intl.formatMessage({ id: "MOMOPAY.PHONE_ERROR", defaultMessage: "Paiement impossible. Vérifiez le numéro de téléphone." }))
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => setVisible(true)}
      >
        <FormattedMessage
          id="MOMOPAY.PAY_WITH_MOMOPAY"
          defaultMessage="payer via Momopay"
        />
      </Button>
      <Dialog open={visible} onClose={() => setVisible(false)}>
        <Paper>
          <div className={classes.container}>
            {!paymentRequested ? (
              <>
                <p>MomoPay</p>
                <TextField
                  disabled={loading}
                  label={intl.formatMessage({
                    id: "SIGNUP.LABEL_NUMTELEPHON",
                    defaultMessage: "Numéro de téléphone",
                  })}
                  id="phone_number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        ({appConfig.phonePrefix})
                      </InputAdornment>
                    ),
                  }}
                  variant="standard"
                  onChange={({ currentTarget }) =>
                    setPhoneNumber(currentTarget.value)
                  }
                  {...error && { error: true, helperText: error }}
                />
                <div>{payment?.displayed_price}</div>
                <br />
                <Button
                  disabled={!phoneNumber}
                  color="secondary"
                  variant="contained"
                  onClick={handleSubmit}
                >
                  Payer
                  {loading && (
                    <>
                      <CircularProgress className={classes.loader} size={20} />
                    </>
                  )}
                </Button>
              </>
            ) : (
              <p>En attente de validation. Vérifiez votre téléphone.</p>
            )}
          </div>
        </Paper>
      </Dialog>
      {/* </MainLayout> */}
    </>
  );
}

export default MomoPay;
