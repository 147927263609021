import React from 'react'
import StepperLayout from './StepperLayout';
import LocationsEdit from './shared/LocationsEdit';
import ConnectedLayout from './shared/ConnectedLayout';
import MainLayout from './MainLayout';
import { useLocation } from 'react-router-dom';
import { RouteStateI } from '../types';


const LocationInfo = () => {
  const { state } = useLocation<RouteStateI>()
  return (
    <MainLayout>
      {state?.status !== "firstLocation" ?
        (<ConnectedLayout >
          <LocationsEdit />
        </ConnectedLayout>)
        :
        (<StepperLayout activeStep={1} >
          <LocationsEdit />
        </StepperLayout>)
      }
    </MainLayout>
  )
}

export default LocationInfo
