import React, { useState } from "react";
import {
  TextField,
  Button,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  CircularProgress,
} from "@material-ui/core";
import { validate } from "validate.js";
import { useHistory } from "react-router-dom";
import { UserContextType, useUserContext } from "../authentication";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { loggerConstraints } from "../utils/constraints";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { useQueryFromPath } from "../utils/useQueryFromPath";
import { actionate } from "../API";
import CustomButton from './shared/CustomButton';
import {FormattedMessage} from "react-intl";
import {useIntl} from "react-intl";

type Props = {
  authentication: UserContextType;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
    },
    container: {
      // backgroundColor: theme.palette.grey[200],
      // width: "100vw",
      // height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    form: {
      display: "flex",
      flexDirection: "column",
    },
    field: {
      margin: theme.spacing(2),
    },
    button: {
      margin: theme.spacing(2),
    },
    caption: {
      textAlign: "center",
    },
    margin: {
      margin: theme.spacing(2),
    },
    spaceL3: {
      marginLeft: theme.spacing(3),
    },
    alert: {
      marginTop: theme.spacing(2),
    },
  })
);

export const LogIn = ({ authentication }: Props) => {
  const intl=useIntl();

  const history = useHistory();

  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState({ emailAddress: "", password: "" });
  const [errors, setErrors] = useState({ emailAddress: "", password: "" });
  const [serverError, setServerError] = useState<null | string>(null);
  const locationSourceId = useQueryFromPath("location_source_id");

  const handleValidation = async () => {
    setIsLoading(true);

    const validationErrors = validate(user, loggerConstraints);
    if (validationErrors) {
      setIsLoading(false);
      setErrors(validationErrors);
    } else {

      try {
        const authorization = await authentication.logIn({
          email: user.emailAddress,
          password: user.password
        });


        setIsLoading(false)
        if (locationSourceId) {
          history.push(`/infos${locationSourceId ? `?location_source_id=${locationSourceId}` : ""}`);
        } else {
          history.push(`/`)
        }

      } catch (error: any) {
        setIsLoading(false);
        if (error.text) {
          setServerError(error.text);
        } else if (error.status === 403) {
          setServerError(
            intl.formatMessage({id:"LOGGER.LOGIN_MDP_INCORRECT",defaultMessage:"Le login ou le mot de passe est incorrect"})
          );
        } else if (error.text === "") {
          setServerError(
            intl.formatMessage({id:'LOGGER.ERREUR_LOGIN_MDP',defaultMessage:"Une erreur s'est produite, veuillez vérifier votre login et votre mot de passe"})
          );
        }       
      }

    }



  };

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  /////////////////////////////////////////////////////////
  /////////////////////// Auto Login /////////////////////
  ///////////////////////////////////////////////////////

  const [displayAutoLogin, setdisplayAutoLogin] = useState(false);
  const [send, setSend] = useState(false);
  const [error, setError] = useState(null);

  const [isSending, setIsSending] = useState(false);

  const autoLogin = () => {
    setdisplayAutoLogin(true);
  };

  const handleAutoLogin = async () => {
    try {
      setIsSending(true)
      const response = await actionate({
        action: "auto_login",
        params:{
          email: encodeURIComponent(user.emailAddress)
        },
        model: "users",
      });
      if (response) {
        setIsSending(false)
        setSend(true);
        setTimeout(() => {
          setdisplayAutoLogin(false);
        }, 2500);
      }
    } catch (e: any) {
      setIsSending(false)
      if (e.status === 422) {
        setError(e.text);
        setTimeout(() => {
          setError(null);
        }, 2500);
      }
    }
  };

  return (
    <>
      <div className={classes.form}>
        {!displayAutoLogin && <><TextField
          InputLabelProps={{ shrink: true }}
          error={errors["emailAddress"] ? true : false}
          id="emailAdress"
          value={user.emailAddress}
          onChange={({ currentTarget }) =>
            setUser({ ...user, emailAddress: currentTarget.value })
          }
          helperText={errors["emailAddress"] ? errors.emailAddress[0] : false}
          label={intl.formatMessage({id:"LOGGER.INPUTLABEL_ADRESSMAIL",defaultMessage:"Adresse email"})}
          className={classes.field}
        />
          <FormControl className={clsx(classes.margin)}>
            <InputLabel htmlFor="standard-adornment-password">
              <FormattedMessage id="LOGGER.INPUTLABEL_MOTPASSE" defaultMessage="Mot de passe"/>
            </InputLabel>
            <Input
              error={errors["password"] ? true : false}
              id="standard-adornment-password"
              type={showPassword ? "text" : "password"}
              value={user.password}
              onChange={({ currentTarget }) =>
                setUser({ ...user, password: currentTarget.value })
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          {serverError && (
            <Alert severity="warning">
              {serverError}
              {/* Identifiant ou mot de passe non reconnu  */}
            </Alert>
            // <Typography
            //   className={classes.caption}
            //   variant="caption"
            //   color="error"
            // >
            //   {serverError}
            // </Typography>
          )}
          <CustomButton
            accent="main"
            className={classes.button}
            submitFn={handleValidation}
            isLoading={isLoading}
          >
            <FormattedMessage
            id="LOGGER.BTN_CONNECTER"
            defaultMessage="Se connecter"/>
          </CustomButton>

          <div className={classes.container}>
            <a href="#" onClick={autoLogin}>
              <FormattedMessage
              id="LOGGER.MDP_OUBLI"
              defaultMessage="Mot de passe oublié ?"/>
            </a>
          </div></>}


        {displayAutoLogin && (
          <>
            <div className={classes.form}>
              <TextField
                id="emailAdress"
                value={user.emailAddress}
                onChange={({ currentTarget }) =>
                  setUser({ ...user, emailAddress: currentTarget.value })
                }
                label={intl.formatMessage({id:'LOGGER.LABEL_ADRESSE_MAIL',defaultMessage:"Adresse email"})}
                className={classes.field}
              />
            </div>

            <CustomButton
              isLoading={isSending}
              accent="main"
              className={classes.button}
              submitFn={handleAutoLogin}
            >
              <FormattedMessage
              id="LOGGER.BTN_REINIT_MDP"
              defaultMessage="M'envoyer un lien de réinitialisation"/>
          </CustomButton>

            {send && (
              <Alert className={classes.alert} severity="success">
                <FormattedMessage
                id="LOGGER.MSG_MAILENVOYE"
                defaultMessage="Un mail vous a été envoyé"/>
              </Alert>
            )}

            {error && (
              <Alert className={classes.alert} severity="warning">
                {error}
              </Alert>
            )}
          </>
        )}

      </div>



    </>
  );
};

function Logger() {
  const classes = useStyles();
  const authentication = useUserContext();

  return (
    <>
      {authentication && (
        <div className={classes.container}>
          <LogIn authentication={authentication} />
        </div>
      )}
    </>
  );
}

export default Logger;
