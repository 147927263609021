import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import { Stepper, Step, StepLabel, Typography } from '@material-ui/core';
import PrevNextButton from './shared/PrevNextButton';
import { RegistrationI } from "../types"
import { IconButton, useMediaQuery } from '@material-ui/core';
import RegistrationContext from "../registrationContext";
import { useHistory } from "react-router-dom";
import PrevButton from './shared/PrevButton';
import { useTitle } from '../utils/useTitle';
import {FormattedMessage} from "react-intl";
import {useIntl} from "react-intl";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      textAlign: "center",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    errorMessages: {
      display: "flex",
      flexDirection: "column",
      maxWidth: 600,
      alignItems: "flex-start",
      margin: "0 auto"
    },
    bold: {
      fontWeight: "bold"
    },
    stepperContainer: {
      position: "relative",

    },
    stepper: {
      paddingTop: theme.spacing(6)

    },


  }),
);



type contextType = {
  infos: RegistrationI;
  setInfos: React.Dispatch<React.SetStateAction<RegistrationI>>,
}

type Props = { activeStep: number, children: any, disabled?:boolean }


const HorizontalStepper = ({ activeStep, children, disabled=false }: Props) => {
  const intl=useIntl();

  const classes = useStyles();
  const { infos }: contextType = useContext(RegistrationContext)
  const history = useHistory()
  const { title, navTitle } = useTitle()
  const steps = getSteps();

  function getSteps() {
    return [intl.formatMessage({id:'STEPPERLAYOUT.INSCRIP',defaultMessage:'Inscription'}), 
            intl.formatMessage({id:'STEPPERLAYOUT.ETABLISS',defaultMessage:'Établissement'}), 
            intl.formatMessage({id:'STEPPERLAYOUT.FORFAIT',defaultMessage:'Forfait'}), 
            intl.formatMessage({id:'STEPPERLAYOUT.SPONSORISATION',defaultMessage:'Sponsorisation'}), 
            intl.formatMessage({id:'STEPPERLAYOUT.VALIDATION',defaultMessage:'Validation'})];
  }

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <div className={classes.root}>
      <div className={classes.stepperContainer}>

        <PrevButton activeStep={activeStep} />


        <Stepper className={classes.stepper} activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{sm && label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>

      <Typography variant="h5" className={classes.instructions}>{title}</Typography>

      <div>
        {children}
      </div>

      <PrevNextButton activeStep={activeStep} disabled={disabled} />

    </div>
  );
}

export default HorizontalStepper
