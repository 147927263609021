import React from 'react'
import ConnectedLayout from './shared/ConnectedLayout';
import Payment from './Payment';
import MainLayout from './MainLayout';
import { RouteStateI } from '../types';
import { useLocation } from 'react-router-dom';
import { useLocationInfos } from '../utils/useLocationInfos';
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";



const useStyles = makeStyles((theme: Theme) =>
  createStyles({

    container: {
      maxWidth: 600,
      margin: "auto"
    },
    spaceY2: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),

    }

  })
);


const LocationValidation = () => {
  const classes = useStyles();

  const { state } = useLocation<RouteStateI>()
  const { location, toBePayed, payments } = useLocationInfos(state?.locationId)  
  return (
    <MainLayout>
      <ConnectedLayout >
        {toBePayed && <div className={classes.container}>
          <Payment toBePayed={toBePayed} workflowState={location?.workflow_state} payments={payments} />
        </div>}
      </ConnectedLayout>
    </MainLayout>
  )
}

export default LocationValidation