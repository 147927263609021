import React from 'react'
import StepperLayout from './StepperLayout';
import ConnectedLayout from './shared/ConnectedLayout';
import Sponsored from './shared/Sponsored';
import MainLayout from './MainLayout';
import { RouteStateI } from '../types';
import { useLocation } from 'react-router-dom';




const LocationSponsored = () => {
  const { state } = useLocation<RouteStateI>()
  return (
    <MainLayout>
      {state?.status !== "firstLocation" ?
        <ConnectedLayout >
          <Sponsored />
        </ConnectedLayout>
        :
        <StepperLayout activeStep={3} >
          <Sponsored />
        </StepperLayout>
      }
    </MainLayout>
  )
}

export default LocationSponsored