import React, {useEffect, useState} from 'react'
import StepperLayout from './StepperLayout';
import ConnectedLayout from './shared/ConnectedLayout';
import Validation from './shared/Validation';
import MainLayout from './MainLayout';
import { RouteStateI } from '../types';
import { useLocation } from 'react-router-dom';

const LocationValidation = () => {
  const { state } = useLocation<RouteStateI>()
const [disabled, setDisabled] = useState(true)

  return (
    <MainLayout>
      {state?.status !== "firstLocation" ?
        (<ConnectedLayout disabled={disabled} >
          <Validation setDisabled={setDisabled}/>
        </ConnectedLayout>)
        :
        (<StepperLayout activeStep={4} disabled={disabled} >
          <Validation setDisabled={setDisabled}/>
        </StepperLayout>)
      }
    </MainLayout>
  )
}

export default LocationValidation