import React, { createContext } from "react"
import { RegistrationI } from "./types"

type contextType = {
  infos: RegistrationI;
  setInfos: React.Dispatch<React.SetStateAction<RegistrationI>>;
  errors: Record<string, string>;
  setErrors: React.Dispatch<React.SetStateAction<Record<string, string>>>;
}

export const appContextValue: contextType = {
  infos: {
    location: {
      category_ids: ["", "", ""],
      categories: [{ attributes: { name: "" } }, { attributes: { name: "" } }, { attributes: { name: "" } }],

    },
    plan: null,
    user: {},
  },
  errors: {},
  setInfos: () => { },
  setErrors: () => { }
}

const RegistrationContext = createContext(appContextValue)

export default RegistrationContext
//setInfos : React.Dispatch<React.SetStateAction<RegistrationI>>
