import { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { RouteStateI } from "../types"

export const useLocationId = () => {
  const [id, setId] = useState<string>()
  const { state, pathname } = useLocation<RouteStateI>()

  useEffect(() => {
    const findPathId = pathname.match(/([1-9][0-9]*)/)
    const pathId = findPathId ? findPathId[0] : undefined

    setId(state?.locationId ? state.locationId : pathId)
  }, [])

  return id
}