import { Button, Dialog, Paper } from "@material-ui/core";
import React, { useState } from "react";
import { PaymentI } from "../../types";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import appConfig from "../../appConfig";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: 200,
      width: 200,
      padding: theme.spacing(6),
    },
  })
);

function LaserPay({ payment }: { payment: PaymentI }) {
  const classes = useStyles();
  const [visible, setVisible] = useState(false);

  const handleSubmit = () => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ payment_id: payment.id, status: 'PAID', transaction_id: Math.floor(Math.random()* 99999) })
  };
  fetch(`${appConfig.webhook_endpoint}/laserpay`, requestOptions)
      .then(response => response.json())
      .then(data => console.log(data));
  }
  
  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => setVisible(true)}
      >
        payer via LaserPay
      </Button>
      <Dialog open={visible} onClose={() => setVisible(false)}>
        <Paper>
          <div className={classes.container}>
            <p>LaserPay</p>
            <div>{payment.displayed_price}</div>
            <Button onClick={handleSubmit} >Payer</Button>
          </div>
        </Paper>
      </Dialog>
    </>
  );
}

export default LaserPay;
