import React, { useEffect, useState } from "react";

import {
  makeStyles,
  createStyles,
  createTheme,
  ThemeProvider,
  Theme,
} from "@material-ui/core/styles";
import { IntlProvider, MessageFormatElement } from "react-intl";

import { CssBaseline } from "@material-ui/core";
import MTNLogoFont from "./fonts/MTNBrighterSans-MediumItalic.otf";
import AppBar from "./components/AppBar";
import { ReactQueryDevtools } from "react-query/devtools";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  Redirect,
} from "react-router-dom";
import Resume from "./components/Resume";
import RegistrationContext, { appContextValue } from "./registrationContext";
import { RegistrationI } from "./types";
import LocationInfo from "./components/LocationInfo";
import { ScrollToTop } from "./utils/ScrollToTop";
import SignUp from "./components/SignUp";
import { useAuth, UserContext } from "./authentication";
import Home from "./components/Home";
import MyLocations from "./components/MyLocations";
import LocationPlan from "./components/LocationPlan";
import LocationSponsored from "./components/LocationSponsored";
import LocationValidation from "./components/LocationValidation";
import { QueryClient, QueryClientProvider } from "react-query";
import OneLocation from "./components/OneLocation";
import BadRoute from "./components/BadRoute";
import LocationPayment from "./components/LocationPayment";
import UserInfos from "./components/UserInfos";
import { useQueryFromPath } from "./utils/useQueryFromPath";
import { ConfigurationContext, useConf } from "./configurationContext";
import appConfig from "./appConfig";

// const mtn: any = {
//   fontFamily: "MTN",
//   fontStyle: "normal",
//   fontDisplay: "swap",
//   fontWeight: 400,
//   src: `
//     local('MTN'),
//     local('MTN-Medium'),
//     url(${MTNLogoFont}) format('woff2')
//   `,
//   unicodeRange:
//     "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
// };

declare module "@material-ui/core/styles/createPalette" {
  interface Palette {
    navBar: Palette["primary"];
    tab: Palette["primary"];
  }
  interface PaletteOptions {
    navBar: PaletteOptions["primary"];
    tab: PaletteOptions["primary"];
  }
}

const theme = createTheme({
  palette: {
    tab: {
      main: appConfig.colors.tabFont,
    },
    navBar: {
      main: appConfig.colors.navBarBg,
      contrastText: appConfig.colors.navBarFont,
    },
    secondary: {
      main: appConfig.colors.btnBg,
      light: appConfig.colors.navBarBg,
    },
    primary: {
      main: "#212121",
    },
  },
  typography: {
    fontFamily: "Roboto, MTN",
  },
  // overrides: {
  //   MuiCssBaseline: {
  //     "@global": {
  //       "@font-face": [mtn],
  //     },
  //   },
  // },
});

const useStyles = makeStyles((theme: Theme) => createStyles({}));

function App() {
  // dynamic favicon and title
  function getFaviconEl(): any {
    return document.getElementById("favicon");
  }
  function getTitleEl(): any {
    return document.getElementById("title");
  }
  const favicon = getFaviconEl();
  favicon.href = appConfig.brand.favicon;
  const title = getTitleEl();
  title.innerText = appConfig.appName;

  const [infos, setInfos] = useState<RegistrationI>(appContextValue.infos);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const queryClient = new QueryClient();

  const locale = process.env.REACT_APP_LOCALE || appConfig.locale;
  const [localeData, setLocaleData] = useState<Record<
    string,
    MessageFormatElement[]
  > | null>(null);

  
  useEffect(() => {
    async function loadLocaleData() {
      const messages = () =>
        import(
          `./${
            process.env.NODE_ENV !== "development" ? "compiled-" : ""
          }lang/${locale}.json`
        );
      setLocaleData(await messages());
    }
    loadLocaleData();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        {localeData && (
          <IntlProvider
            messages={localeData}
            locale={process.env.REACT_APP_LOCALE || locale}
            defaultLocale="en"
          >
            <RegistrationContext.Provider value={{ infos, setInfos, errors, setErrors }}>
              <Router>
                <Authenticator />
              </Router>
            </RegistrationContext.Provider>
          </IntlProvider>
        )}
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ThemeProvider>
  );
}

const Authenticator = () => {
  const authentication = useAuth();
  const configuration = useConf({ authentication });

  const autoToken = useQueryFromPath("auto_login_token");
  const [serverError, setServerError] = useState<null | string>(null);
  const history = useHistory();
  const [firstTimeAutoToken, setFirstTimeAutoToken] = useState(true);

  autoToken &&
    firstTimeAutoToken &&
    (async () => {
      setFirstTimeAutoToken(false);
      const authorization = await authentication.logIn({
        auto_login_token: autoToken,
      });
      if ("error" in authorization) {
        setServerError(
          " Il n'y a pas de lien de connexion automatique avec ce token."
        );
        history.push("/");
      }
    })();

  return (
    <UserContext.Provider value={authentication}>
      <ScrollToTop>
        <CssBaseline />
        <AppBar />
        <Switch>
          <Route exact path="/moncompte">
            <SignUp />
          </Route>

          {authentication && authentication.initialized && (
            <>
              {authentication.accessToken ? (
                <>
                  <ConfigurationContext.Provider value={configuration}>
                    <Route exact path="/infos">
                      <LocationInfo />
                    </Route>
                    <Route exact path="/plan">
                      <LocationPlan />
                    </Route>
                    <Route exact path="/sponsorisation">
                      <LocationSponsored />
                    </Route>
                    <Route exact path="/validation">
                      <LocationValidation />
                    </Route>
                    <Route exact path="/resume">
                      <Resume />
                    </Route>
                    <Route exact path="/location/:id">
                      <OneLocation />
                    </Route>
                    <Route exact path="/payment">
                      <LocationPayment />
                    </Route>
                    <Route exact path="/mesinfos">
                      <UserInfos />
                    </Route>
                    <Route exact path="/">
                      <MyLocations />
                    </Route>
                  </ConfigurationContext.Provider>
                </>
              ) : (
                <>
                  <Redirect
                    to={{
                      pathname: "/",
                    }}
                  />
                  <Route exact path={["/locations/:id", "/"]}>
                    <Home autoLoginError={serverError} />
                  </Route>
                </>
              )}
            </>
          )}
        </Switch>
      </ScrollToTop>
    </UserContext.Provider>
  );
};

export default App;
