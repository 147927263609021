import React, { useEffect } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Button, Paper, Typography } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import clsx from "clsx";
import { DynamicPlanI, RouteStateI } from "../../types";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { fetchOne } from "../../API";
import { useUserContext } from "../../authentication";
import { usePlan } from "../../utils/usePlan";
import RichTextBuilder from "./RichTextBuilder";
import { FormattedMessage } from "react-intl";
import appConfig from "../../appConfig";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    optionContainer: {
      display: "flex",
      flexDirection: "row",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    container: {
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(2),
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(6),
      maxWidth: 600,
      marginRight: "auto",
      marginLeft: "auto",
    },
    goldBg: {
      backgroundColor: "#D4AF37",
    },
    silverBg: {
      backgroundColor: "#8D8787",
    },
    bronzeBg: {
      backgroundColor: "#614E1A",
    },
    white: {
      color: "white",
    },
    btn: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      backgroundColor: "white",
      color: "black",
      width: "100%",
      "&:hover": {
        backgroundColor: "white",
      },
      "&:focus": {
        backgroundColor: "white",
      },
    },
    selectedBtn: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      width: "100%",
    },
    price: {
      fontWeight: "bold",
    },
    bold: {
      fontWeight: "bold",
    },
  })
);

type optionsProps = {
  options: string[];
};

const OptionsPlan = ({ options }: optionsProps) => {
  const classes = useStyles();

  return (
    <>
      {options.map((option) => (
        <div key={option} className={classes.optionContainer}>
          <CheckIcon classes={{ root: classes.white }} />
          <Typography classes={{ root: classes.white }}>{option}</Typography>
        </div>
      ))}
    </>
  );
};

type Props = {
  title?: DynamicPlanI["name"];
  choosePlan?: (plan: DynamicPlanI) => void;
  selectedPlan?: DynamicPlanI | null;
  deletePlan?: () => void;
  plan: DynamicPlanI;
};

const Plan = ({ deletePlan, selectedPlan, title, plan, choosePlan }: Props) => {
  const classes = useStyles();
  const { accessToken } = useUserContext();
  const { state } = useLocation<RouteStateI>();

  // const plan = usePlan(title)
  const { data } = useQuery(
    ["locations", state?.locationId, `upgrade_plan?type=${title}`],
    () => {
      if (state?.locationId) {
        return fetchOne(
          "locations",
          { id: state?.locationId },
          accessToken,
          `upgrade_plan?type=${title}`
        );
      }
    },
    { enabled: Boolean(state?.status === "upgrade" && state?.locationId) }
  );
  const upgradePrice = data?.price;

  return (
    <div>
      {plan && (
        <Paper
          className={clsx({
            [classes.white]: true,
            [classes.container]: true,
            [classes.goldBg]: plan.name === "Gold",
            [classes.silverBg]: plan.name === "Silver",
            [classes.bronzeBg]: plan.name === "Bronze",
          })}
        >
          <Typography classes={{ root: classes.white }} variant="h6">
            {plan.printed_name}
          </Typography>
          {plan.sub_title && !upgradePrice && (
            <RichTextBuilder nodes={plan.sub_title} />
          )}
          {upgradePrice && (
            <Typography className={classes.bold}>
              <FormattedMessage
                id="PLAN.TEXTE_RESTE_PAYER"
                defaultMessage="reste à payer {price} {currency} jusqu'à la fin de votre abonnement"
                values={{
                  price: upgradePrice.cents,
                  currency: appConfig.currency,
                }}
              />
            </Typography>
          )}
          {plan.description && <RichTextBuilder nodes={plan.description} />}

          {/* {getOptions()} */}
          <div>
            {selectedPlan?.name === plan.name
              ? deletePlan && (
                  <Button
                    className={classes.selectedBtn}
                    variant="contained"
                    color="secondary"
                    onClick={() => deletePlan()}
                    // classes={{ root: classes.btn }
                  >
                    <FormattedMessage
                      id="PLAN.BTN_PLANSELECT"
                      defaultMessage="Ce plan est selectionné"
                    />
                  </Button>
                )
              : choosePlan && (
                  <Button
                    onClick={() => choosePlan(plan)}
                    classes={{ root: classes.btn }}
                  >
                    <FormattedMessage
                      id="PLAN.BTN_CHOISIRPLAN"
                      defaultMessage="Choisir ce plan"
                    />
                  </Button>
                )}
          </div>
        </Paper>
      )}
    </div>
  );
};

export default Plan;
