import React from 'react'
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { useLocation } from 'react-router-dom';
import { ProductI } from '../types';
import { useLocationId } from '../utils/useLocationId';
import { useLocationInfos } from '../utils/useLocationInfos';
import { isAfter } from 'date-fns';
import { Paper, Typography } from '@material-ui/core';
import ProductInfos from './shared/ProductInfos';
import MainLayout from './MainLayout';
import {FormattedMessage} from "react-intl";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btnContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    button: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      width: "100%",
      maxWidth: 600,

    },
    subtitle: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(3),
    },
    container: {
      maxWidth: 600,
      margin: "auto"
    },

    spaceY2: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),

    },
    spaceY4: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),

    }

  })
);

const ProductsHistory = () => {
  const classes = useStyles()
  const id = useLocationId()
  const { paidProducts, inactiveProducts } = useLocationInfos(id)

  const futurProducts = paidProducts && paidProducts?.filter((pp) => {
    if (isAfter(Date.parse(pp.date_start ? pp.date_start : ""), Date.now())) return pp
  })

  return (
    <>
      {futurProducts && <MainLayout>
        <div className={classes.container}>

          <Typography className={classes.subtitle} variant='h5'>
            <FormattedMessage
            id="PRODUCTSHISTORY.TEXTE_FUTUR_SOUSCRIPT"
            defaultMessage="Souscriptions futures (déjà payés)"/>
        </Typography>
          {futurProducts?.map(fp => <div key={fp.id}>

            <ProductInfos noCard product={fp} />
          </div>)}
        </div>

      </MainLayout>}

      {inactiveProducts && <MainLayout>
        <div className={classes.container}>

          <Typography className={classes.subtitle} variant='h5'>
            <FormattedMessage
            id="PRODUCTSHISTORY.PAST_SOUSCRIPT"
            defaultMessage="Souscriptions passées"/>
        </Typography>
          {inactiveProducts?.map(ip => <>

            <ProductInfos noCard product={ip} />
          </>)}
        </div>

      </MainLayout>}

    </>
  )
}

export default ProductsHistory
