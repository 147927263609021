
// on note ici les trucs bizares concernant nos monnaies.
// Si devient trop gros on utilisera une lib.

export const moneySpec: Record<string, {exponent?: number}> = {
  "XOF":{exponent: 0},
}
export const centsToUnit = (cents:number, currency: string):number => {
  let exponent = moneySpec[currency]?.exponent
  exponent = (exponent || exponent === 0) ? exponent : 2 // a cause du f***ing 0 == false 
  console.log(exponent)
  return cents / Math.pow(10, exponent)
}

