import React, { } from "react";
import { Typography, Button, useMediaQuery } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { LogIn } from './Logger';
import { useUserContext } from "../authentication";
import clsx from "clsx";
import { useQueryFromPath } from '../utils/useQueryFromPath';
import { Alert } from "@material-ui/lab";
import CustomButton from "./shared/CustomButton";
import appConfig from "../appConfig";
import {FormattedMessage} from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    col: {
      flexDirection: "column",
      marginTop: "0px!important",
      minHeight: "100vh",
      justifyContent: "space-around",
    },
    container: {
      display: "flex",
      justifyContent: "center",
      //alignItems: "center",
      //height: "100vh",
      //width: "100vw",
      marginRight: 10,
      marginLeft: 10,
      marginTop: 250,
    },
    flexItem1: {
      display: "flex",
      //justifyContent: "space-between",
      flexDirection: "column",
      marginRight: theme.spacing(8),
    },
    flexItem2: {
      //maxWidth: 400,
      display: "flex",
      //justifyContent: "space-between",
      flexDirection: "column",
    },
    paper: {
      padding: theme.spacing(2),
    },
    buttonContainer: {
      paddingTop: theme.spacing(2),
      // display: "flex",
      // flexDirection: "column",
      // alignItems: "center",
    },
    button: {
      width: "100%",
      maxWidth: 250,
      marginBottom: theme.spacing(2),
    },
    headline: {
      marginTop: theme.spacing(8),
    },
  })
);

type Props = {
  autoLoginError?: any;
};

const Home = (
  { autoLoginError }: Props
) => {
  const theme = useTheme()
  const classes = useStyles();
  const history = useHistory();
  const authentication = useUserContext()
  const downSm = useMediaQuery(theme.breakpoints.down('sm'));
  const locationSourceId = useQueryFromPath("location_source_id");

  return (
    <>
      <div className={clsx(classes.container, downSm && classes.col)}>
        {/* <Paper elevation={0} className={classes.paper}> */}

        <div className={classes.flexItem1}>

          {!locationSourceId ? <Typography variant="h3">{appConfig.appName}</Typography>
            : <Typography variant="h4">
              <FormattedMessage
              id="HOME.TEXTE_REVENDIC_ETABLISS"
              defaultMessage="Vous souhaitez revendiquer un établissement ?"/> <br /> 
              <FormattedMessage
              id="HOME.TEXTE_INSCRIT_CONNECT"
              defaultMessage="Connectez vous ou inscrivez vous"/>
              </Typography>}


          <Typography className={classes.headline} variant="h6">
            <FormattedMessage
            id="HOME.PAS_DE_COMPTE"
            defaultMessage="Pas encore de compte ?"/>
          </Typography>
          <Typography variant="h6">
            <FormattedMessage
            id="HOME.MSG_ENREGISTREMENT_ETABLISS"
            defaultMessage="Enregistrez votre établissement en seulement quelques clics!"/>
          </Typography>

          <div className={classes.buttonContainer}>
            <CustomButton
              submitFn={() => history.push(`/moncompte${locationSourceId ? `?location_source_id=${locationSourceId}` : ""}`, { status: "firstLocation" })} 
              accent="main"
              className={classes.button}
            >
              <FormattedMessage
              id="HOME.BTN_SINSCRIRE"
              defaultMessage="S'INSCRIRE"/>
            </CustomButton>
            {/* <Button onClick={() => history.push("/authentication") }className={classes.button} variant="outlined">SE CONNECTER</Button> */}
          </div>


        </div>

        <div className={classes.flexItem2}>
          <Typography className="headline" variant="h6">
            <FormattedMessage
            id="HOME.MSG_COMPTE_EXIT"
            defaultMessage="Déja un compte ?"/>
            </Typography>

          {autoLoginError && <Alert severity="warning">{autoLoginError}</Alert>}

          <LogIn authentication={authentication} />

        </div>
        {/* </Paper> */}
      </div>
    </>
  );
};

export default Home;
