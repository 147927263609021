import React, { useEffect, useState } from "react";
import {
  Typography,
} from "@material-ui/core";
import * as ActionCable from "@rails/actioncable";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { LocationInfoI, PaymentI, ProductI } from "../../types";
import Alert from "@material-ui/lab/Alert";
import appConfig from "../../appConfig";
import SalespeopleSelector from "./SalespeopleSelector";
import PaymentMethod from "./PaymentMethod";
import { FormattedMessage, useIntl } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toBePayed: {
      marginBottom: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(4),
      margin: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
    },

    subtitle: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(3),
    },
    alertBtn: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    space: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    accordion: {
      border: `1px solid ${theme.palette.divider}`,
      "&:not(:last-child)": {
        borderBottom: 0,
      },
      "&:before": {
        display: "none",
      },
    },
  })
);

type Props = {
  toBePayed: ProductI[];
  workflowState: PaymentI["workflow_state"] | LocationInfoI["workflow_state"];
  payments: PaymentI[];
};

const Payment = ({ workflowState, toBePayed, payments }: Props) => {
  const intl = useIntl();

  const classes = useStyles();

  const [paymentMethod, setPaymentMethod] = useState<string | null>(null);

  const cable = ActionCable.createConsumer(appConfig.actionCable);
  useEffect(() => {
    // Action cable pour le refresh
    // on prévoit toujours le mystérieux cas "plusieurs payments"
    payments.map((payment) => {
      const bob = cable.subscriptions.create(
        {
          channel: "PaymentsChannel",
          payment_id: payment.id,
        },
        {
          received: (message) => {
            window.location.reload();
          },
        }
      );
      console.log(bob);
    });
  }, [payments, cable]);

  return (
    <div>
      {toBePayed && (
        <>
          {
            <Typography className={classes.subtitle} variant="h5">
              <FormattedMessage
                id="PAYMENT.MSG_REGULARPAIEMENT"
                defaultMessage="Paiement à régulariser"/>
            </Typography>
          }

          {toBePayed.map((tbp: ProductI) => (
            <div key={tbp.id} className={classes.toBePayed}>
              <Typography variant="h6" color="textSecondary">
                {["first_plan", "renew_plan", "upgrade_plan"].includes(
                  tbp.relation_type
                ) &&
                  intl.formatMessage(
                    {
                      id: "PAYMENT.TEXTE_PACKAGE",
                      defaultMessage: "Souscription au plan {package_name}",
                    },
                    { package_name: tbp.relation_value }
                  )}
                {tbp.relation_type === "add_sponso" &&
                  intl.formatMessage({
                    id: "PAYMENT.TEXTE_SPONSOR",
                    defaultMessage: "Sponsorisation",
                  })}
              </Typography>
              <Typography>
                <FormattedMessage
                  id="PAYMENT.TEXTE_DUREE"
                  defaultMessage="Durée: {duration} mois"
                  values={{ duration: tbp.duration_in_months }}
                />
                <br />
                <FormattedMessage
                  id="PAYMENT.TEXTE_PRICE"
                  defaultMessage="Prix: {price}"
                  values={{ price: tbp.displayed_price }}
                />
              </Typography>
              {tbp.payment?.workflow_state === "rejected" && (
                <Alert className={classes.alertBtn} severity="warning">
                  <FormattedMessage
                    id="PAYMENT.ALERTE_REJECT_PAYEMENT"
                    defaultMessage="Le paiement a été rejeté, veuillez consulter vos emails"
                  />
                </Alert>
              )}
            </div>
          ))}

          {/* si l'établissement est en attente de validation */}
          {workflowState === "awaiting_validation" && (
            <Alert className={classes.alertBtn} severity="info">
              <FormattedMessage
                id="PAYMENT.MSG_VALIDATION_ENCOURS"
                defaultMessage="L'établissement est en cours de validation par {appName}"
                values={{ appName: appConfig.appName }}
              />
            </Alert>
          )}

          <div>
            {payments &&
              payments.map((payment, i: number) => (
                <div key={payment?.id}>
                  {payments?.length > 1 && (
                    <Typography className={classes.space}>
                      <FormattedMessage
                        id="PAYMENTACTION.MSG_PAIEMENT"
                        defaultMessage="Paiement n° {number}"
                        values={{ number: i + 1 }}
                      />
                    </Typography>
                  )}
                  {/* si le paiement est en attente de validation */}
                  {payment?.workflow_state === "awaiting_validation" && (
                    <Alert className={classes.alertBtn} severity="info">
                      <FormattedMessage
                        id="PAYMENTACTION.MSG_PAIEMENTVALIDATION"
                        defaultMessage="Votre paiement est en cours de validation par {appName}"
                        values={{ appName: appConfig.appName }}
                      />
                    </Alert>
                  )}

                  <Typography variant="h6" color="textSecondary">
                    <FormattedMessage id="PAYMENT.TEXT_TOTAL" defaultMessage="Total"/>
                  </Typography>
                  {payment && (
                    <Typography>{payment?.displayed_price}</Typography>
                  )}

                  {/* si le paiement est à effectuer */}
                  {workflowState !== "awaiting_validation" && ["awaiting_payment", "rejected"].includes(payment?.workflow_state) && (
                    <>
                      <Typography
                        className={classes.alertBtn}
                        variant="h6"
                        color="textSecondary"
                      >
                        <FormattedMessage id="PAYMENT.TEXT_CHOIXMOYPAY" defaultMessage="Choisissez un moyen de paiement :"/>
                      </Typography>
                      <div>
                        {appConfig.payment_methods.map(
                          (method: "laserpay" | "default" | "momopay") => (
                            <PaymentMethod
                              key={method}
                              expanded={paymentMethod === method}
                              onChange={(e, value) =>
                                setPaymentMethod(value ? method : null)
                              }
                              method={method}
                              payment={payment}
                            />
                          )
                        )}
                      </div>
                    </>
                  )}
                  <SalespeopleSelector toBePayed={toBePayed} />
                </div>
              ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Payment;
