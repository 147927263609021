import React, { useContext, useEffect, useState } from "react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { addDays } from "date-fns";
import RegistrationContext from "../../registrationContext";
import differenceInDays from "date-fns/differenceInDays";
import { RouteStateI } from "../../types";
import { useLocation } from "react-router-dom";
import appConfig from "../../appConfig";
import {FormattedMessage } from "react-intl";
import {useIntl} from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginLeft: "auto",
      marginRight: "auto",
      maxWidth: 600,
      marginBottom: theme.spacing(4),
    },
    typo: {
      fontSize: 18,
    },
  })
);

const Sponsored = () => {
  const intl=useIntl();

  const classes = useStyles();
  const { infos, setInfos } = useContext(RegistrationContext);
  const [duration, setDuration]= useState<string>("")
  
  useEffect(() => {
    if (infos.location.sponsored_end_at) {
      const sponso= differenceInDays(
        infos.location.sponsored_end_at,
        infos.location.sponsored_start_at
      ).toString()
     setDuration(sponso)
    }
  }, []);

  

  const { state } = useLocation<RouteStateI>()


  const chooseSponsored = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDuration(event.target.value)
    const today = new Date();
    switch (event.target.value) {
      case "0":
        if (
          infos.location.sponsored_start_at &&
          infos.location.sponsored_end_at
        ) {
          const newLocation = { ...infos.location };
          delete newLocation.sponsored_end_at;
          delete newLocation.sponsored_start_at;
          setInfos({ ...infos, location: { ...newLocation } });
        }
        break;
      case "30":
        setInfos({
          ...infos,
          location: {
            ...infos.location,
            sponsored_start_at: today,
            sponsored_end_at: addDays(new Date(), 30),
          },
        });
        break;
      case "90":
        setInfos({
          ...infos,
          location: {
            ...infos.location,
            sponsored_start_at: today,
            sponsored_end_at: addDays(new Date(), 90),
          },
        });
        break;
      default:
        break;
    }
  };

  return (
    <div className={classes.container}>
      <br />
      <Typography className={classes.typo}>
        <FormattedMessage
        id="SPONSORED.MSG_VISIBILITE_ENTREPRISE"
        defaultMessage="Pour donner plus de visibilité à votre entreprise dans les recherches, faites-la apparaitre en premier !"/>
      </Typography>
      <br />
      <Typography className={classes.typo}>
        <FormattedMessage
        id="SPONSORED.MSG_SECTEUR_ACTIVITE"
        defaultMessage="Pour chaque recherche concernant votre secteur d’activité, votre entreprise apparaîtra en premier auprès de tous les utilisateurs se trouvant à proximité."/>
      </Typography>
      <br />
      <Typography className={classes.typo}>
        <FormattedMessage
        id="SPONSORED.MSG_ASSURANCE_CLIENT"
        defaultMessage="Vous êtes donc sûr de toucher une base de clients potentiels qualifiée."/>
      </Typography>

      <br />
      <FormControl
        component="fieldset"
        disabled={infos?.plan?.name === "Bronze" || (state?.plan === "Bronze" && state?.status === "renewal") }
      >
        <FormLabel component="legend">
          <FormattedMessage
          id="SPONSORED.FORMLAB_TARIF"
          defaultMessage="Tarifs:"/>
        </FormLabel>
        <RadioGroup
          aria-label="sponsored"
          name="sponsored"
          value={duration}
          onChange={chooseSponsored}
        >
          {Object.entries(appConfig.sponsorship_options).map(([days, price]) => 
            <FormControlLabel
            value={days}
            control={<Radio />}
            label={intl.formatMessage({
              id:'SPONSORED.LABEL_EMPLACSPONSOR',
              defaultMessage:`Emplacement sponsorisé durant {days} jours : {price} {currency}`
            }, {days, price: price.toLocaleString('fr'), currency: appConfig.currency  })}
          />
          )}

          {state?.status !== "sponso" && <FormControlLabel
            value="0"
            control={<Radio />}
            label={intl.formatMessage({id:"SPONSORED.MSG_NOSPONSOR",defaultMessage:"Je ne souhaite pas sponsoriser mon établissement"})}
          />}
        </RadioGroup>
        <br />
        <Typography className={classes.typo}>
          <i>
            
            <FormattedMessage
            id="SPONSORED.MSG_SPONSOR"
            defaultMessage="L’option emplacement sponsorisé est réservée aux clients Silver et
            Gold"/>
          </i>
        </Typography>
      </FormControl>
    </div>
  );
};

export default Sponsored;
