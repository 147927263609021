import React from 'react'
import { IconButton, Typography } from '@material-ui/core'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import { useNavigation } from '../../utils/useNavigation';
import {FormattedMessage} from "react-intl";
type Props = {
  activeStep: number
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    stepperBtn: {
      position: "absolute",
      maxHeight: 40,
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
      top: "-10px",
      left: "-25px"

    }
  }))

const PrevButton = ({ activeStep }: Props) => {
  const classes = useStyles();
  const { goPrev } = useNavigation()

  return (
    <div>
      {activeStep !== 0 && activeStep !== 1 &&
        <IconButton
          aria-label="back"
          onClick={() => goPrev()}
          className={classes.stepperBtn}
          color="primary"
        >
          <ArrowBackIosIcon />
          <Typography>
            <FormattedMessage
            id="PREVBUTTON.BTN_RETOUR"
            defaultMessage="retour"/>
          </Typography>

        </IconButton>
      }
    </div>
  )
}

export default PrevButton
