import {
  Typography,
  TextField,
  Button,
} from "@material-ui/core";
import React, { useState } from "react";
import { createOne } from "../../API";
import { useUserContext } from "../../authentication";
import { useLocationId } from "../../utils/useLocationId";
import { useLocationInfos } from "../../utils/useLocationInfos";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useLocation, useHistory } from "react-router-dom";
import { PaymentI } from "../../types";
import { useConfigurationContext } from "../../configurationContext";
import appConfig from "../../appConfig";
import { FormattedMessage, useIntl } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: "flex",
      flexDirection: "column",
    },
    space: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    checkbox: {
      display: "flex",
      alignItems: "center",
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },

    alertBtn: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    associateBtn: {
      width: "50%",
      marginTop: theme.spacing(2),
    },
  })
);

const PaymentAction = ({ payment }: { payment: PaymentI }) => {

  const classes = useStyles();
  const id = useLocationId();
  const { toBePayed, payments, location } = useLocationInfos(id);
  const { accessToken } = useUserContext();
  const [isPayed, setIsPayed] = useState(false);
  const { pathname } = useLocation();
  const history = useHistory();
  const [momo, setMomo] = useState({});


  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    type: "momo_transaction_id" | "momo_phone_used"
  ) => {
    switch (type) {
      case "momo_transaction_id":
        setMomo({ ...momo, momo_transaction_id: e.target.value });
        break;
      case "momo_phone_used":
        setMomo({ ...momo, momo_phone_used: e.target.value });
        break;
      default:
        break;
    }
  };

  const handlePayment = async () => {
    if (toBePayed && toBePayed[0]?.payment?.id) {
      const response = await createOne(
        "payments",
        { attributes: momo },
        accessToken,
        "send_codes",
        toBePayed[0]?.payment.id
      );
      setIsPayed(response.ok);
      if (!response.ok) {
        const res = await response.json();
      } else {
        window.location.reload()
      }
    }
  };


  return (
    <div>
      {payments &&
        payments.map((payment, i: number) => (
          <div key={payment?.id}>
            {/* si le paiement est à effectuer */}
            {!isPayed &&
              location?.workflow_state !== "awaiting_validation" &&
              toBePayed &&
              ["awaiting_payment", "rejected"].includes(toBePayed[0]?.payment?.workflow_state || "") && (
                <>
                  <Typography>
                    {appConfig.default_payment_wording.introduction}

                  </Typography>
                  <form className={classes.form} noValidate autoComplete="off">
                    <TextField
                      onChange={(e) => handleChange(e, "momo_transaction_id")}
                      className={classes.space}
                      id="transaction_id"
                      label={appConfig.default_payment_wording.momo_transaction_id}
                    />
                    <TextField
                      onChange={(e) => handleChange(e, "momo_phone_used")}
                      className={classes.space}
                      id="reference_number"
                      // label="N° de téléphone de la transaction"
                      label={appConfig.default_payment_wording.momo_phone_used}
                    />

                    <Button
                      onClick={() => handlePayment()}
                      className={classes.alertBtn}
                      variant="contained"
                      color="secondary"
                    >
                      <FormattedMessage id="PAYMENTACTION.BTN_PAYER" defaultMessage="Payer" />
                    </Button>

                    {pathname === "/payment" && (
                      <Button
                        variant="outlined"
                        onClick={() => history.push("/")}
                      >
                        <FormattedMessage
                          id="PAYMENTACTION.BTN_MON_ESPACE"
                          defaultMessage="Mon espace" />
                      </Button>
                    )}
                  </form>
                </>
              )}
          </div>
        ))}
    </div>
  );
};

export default PaymentAction;
