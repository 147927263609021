import React, {useState} from 'react'
import StepperLayout from './StepperLayout';
import ConnectedLayout from './shared/ConnectedLayout';
import Plans from './shared/Plans';
import MainLayout from './MainLayout';
import { RouteStateI } from '../types';
import { useLocation } from 'react-router-dom';



const LocationPlan = () => {
  const { state } = useLocation<RouteStateI>()
  const [disabled, setDisabled] = useState(true)

  return (

    <MainLayout>
      {state?.status !== "firstLocation" ?
        (<ConnectedLayout disabled={disabled} >
          <Plans setDisabled={setDisabled} />
        </ConnectedLayout>)
        :
        (<StepperLayout activeStep={2} disabled={disabled} >
          <Plans setDisabled={setDisabled} />
        </StepperLayout>)
      }
    </MainLayout>
  )
}

export default LocationPlan