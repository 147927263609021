// Contraintes pour validate.js
import {createIntl, createIntlCache, RawIntlProvider} from 'react-intl'
import appConfig from '../appConfig';


const cache = createIntlCache()

const intl = createIntl({
  locale: process.env.REACT_APP_LOCALE || appConfig.locale,
  messages: {}
}, cache)

export const loggerConstraints = {
  emailAddress: {
    presence: {
      allowEmpty: false,
      message: intl.formatMessage({id:'CONSTRAINTS.MSG_ENTER_MAIL_1',defaultMessage:"^Entrez une adresse email"})
    },
    email: {
      message: intl.formatMessage({id:'CONSTRAINTS.MSG_ENTER_MAIL_VALID_1',defaultMessage:"^Entrez une adresse email valide"})
    }
  },
};

export const constraints = {
  emailAddress: {
    presence: {
      allowEmpty: false,
      message: intl.formatMessage({id:'CONSTRAINTS.MSG_ENTER_MAIL_2',defaultMessage:"^Entrez une adresse email"})
    },
    email: {
      message: intl.formatMessage({id:'CONSTRAINTS.MSG_ENTER_MAIL_VALID_2',defaultMessage:"^Entrez une adresse email valide"})
    }
  }
};

export const textConstraints = {
  text: {
    presence: {
      allowEmpty: false,
      message: intl.formatMessage({id:'CONSTRAINTS.MSG_CHAMP_NONVIDE',defaultMessage:"^Ce champ ne peut pas être vide"})
    },
  },
}

export const isEmptyConstraint = {
  isEmpty: {
    presence: {
      allowEmpty: false,
      message: "^can be empty"
    }
  }
}

export const urlConstraints = {
  web: {
    url: {
      schemes: ["http", "https", "ftp"],
      message: intl.formatMessage({id:'CONSTRAINTS.MSG_URL_INCORRECT',defaultMessage:`^L'url  est incorrecte (indice: elle doit commencer par "http://" ou "https://")`})
    },
  },
}

export const emailConstraints = {
  mail: {
    email: {
      message: intl.formatMessage({id:'CONSTRAINTS.MSG_MAIL_NONVALID',defaultMessage:"^Le mail n'est pas valide"}),
    },
  },

}
