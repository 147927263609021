import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import validate from "validate.js";
import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import GMap from "./GMap";
import RegistrationContext from "../../registrationContext";
import { useQuery } from "react-query";
import { fetchOne, fetchList } from "../../API";
import { useUserContext } from "../../authentication";
import { useQueryFromPath } from "../../utils/useQueryFromPath";
import { LocationInfoI } from "../../types";
import appConfig from "../../appConfig";
import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      marginTop: theme.spacing(2),
    },
    form: {
      marginLeft: "auto",
      marginRight: "auto",
      maxWidth: 600,
      display: "flex",
      flexDirection: "column",
      marginBottom: theme.spacing(4),
    },
    mapContainer: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(6),

      width: "100%",
      height: 520,
    },
    mapTypo: {
      marginBottom: theme.spacing(2),
    },
    category: {
      marginTop: theme.spacing(2),
    },
    space: {
      marginTop: theme.spacing(4),
      paddingTop: theme.spacing(4),
    },
  })
);

const LocationsEdit = () => {
  const intl = useIntl();

  const classes = useStyles();
  const { accessToken } = useUserContext();
  const { infos, setInfos, errors, setErrors } = useContext(RegistrationContext);
  const locationSourceId = useQueryFromPath("location_source_id");

  const { status, data: results } = useQuery<{ result: LocationInfoI }>(
    ["locations", { id: locationSourceId }],
    () => {
      return fetchOne("locations", { id: locationSourceId }, accessToken);
    },
    { enabled: Boolean(locationSourceId) }
  );
  const revendicatedLocation = results?.result;

  useEffect(() => {
    setInfos({
      ...infos,
      location: {
        ...revendicatedLocation,
      },
    });
  }, [revendicatedLocation]); // on ajoute pas les autres dependances sinon on boucle

  // fetch the categories
  const categoryFetchObject = {
    fields: { categories: ["name", "id"] },
    sort: "name",
    page: {
      number: 1,
      size: 100,
    },
  };
  const { data: categoryData } = useQuery<{
    results: { name: string; id: string }[];
    pageCount: number;
    total: number;
  }>(["categories", categoryFetchObject], () =>
    fetchList("categories", categoryFetchObject, accessToken)
  );
  const categories = categoryData?.results && categoryData?.results;
  const selectableCategories = [{ id: "0", name: intl.formatMessage({ id: "LOCATIONSEDIT.NOM_PASCATEG", defaultMessage: "Pas de catégorie" }) }];
  if (categories) {
    categories.forEach((category) => selectableCategories.push(category));
  }
  const handleValue = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: string) => {
    const value = event.target.value || null // si pas de valeur, on met null (évite les validations sur des chaines vides)
    setInfos({
      ...infos,
      location: { ...infos.location, [field]: value },
    });
  }

  const handleCategory = (
    event: React.ChangeEvent<{ value: any }>,
    i: number
  ) => {

    const categoryId = +(event.target.value as string);
    const newCategories = infos.location.categories
      ? [...infos.location.categories]
      : [];
    newCategories.splice(i, 1, categoryId ? { id: categoryId } : null);

    setInfos({
      ...infos,
      location: {
        ...infos.location,
        categories: [...newCategories.filter((el) => el != null)],
      },
    });
  };


  const constraints = {
    name: {
      presence: {
        allowEmpty: false,
        message: intl.formatMessage({ id: 'CONSTRAINTS.MSG_CHAMP_NONVIDE', defaultMessage: "^Ce champ ne peut pas être vide" })
      }
    },
    website: {
      url: {
        schemes: ["http", "https"],
        message: intl.formatMessage({ id: 'CONSTRAINTS.MSG_URL_INCORRECT', defaultMessage: `^L'url  est incorrecte (indice: elle doit commencer par "http://" ou "https://")` })
      },
    },
    email: {
      email: {
        message: intl.formatMessage({ id: 'CONSTRAINTS.MSG_MAIL_NONVALID', defaultMessage: "^Le mail n'est pas valide" }),
      },
    },
  }

  const checkField = () => {
    const errorsFound = validate(infos.location, constraints);
    console.log(errorsFound)
    errorsFound ? setErrors(errorsFound) : setErrors({});
  }

  return (
    <form className={classes.form}>
      <TextField
        InputLabelProps={{ shrink: true }}
        className={classes.textField}
        onChange={(event) => handleValue(event, "name")}
        label={intl.formatMessage({ id: 'LOCATIONEDIT.TEXTFIELD_LABEL_NOMETABLISS', defaultMessage: `Nom de l'établissement` })}
        onBlur={(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => checkField()}
        required
        value={infos.location.name}
        error={errors.name ? true : false}
        helperText={errors.name && errors.name[0]}
      />
      <TextField
        InputLabelProps={{ shrink: true }}
        className={classes.textField}
        value={infos.location.short_description}
        onChange={(event) => handleValue(event, "short_description")}
        label={intl.formatMessage({ id: 'LOCATIONSEDIT.LABEL_DESCRIP_COURTE', defaultMessage: `Courte description` })}
      />
      <TextField
        InputLabelProps={{ shrink: true }}
        className={classes.textField}
        defaultValue=""
        value={infos.location.description}
        onChange={(event) => handleValue(event, "description")}
        label={intl.formatMessage({ id: 'LOCATIONEDIT.LABEL_DESCRIPT_LONG', defaultMessage: `Description plus longue` })}
        multiline
        rows={4}
      />

      <TextField
        InputLabelProps={{ shrink: true }}
        className={classes.textField}
        defaultValue=""
        value={infos.location.legal_form}
        onChange={(event) => handleValue(event, "legal_form")}
        label={intl.formatMessage({ id: 'LOCATIONEDIT.LABEL_FORME_JURIDIQ', defaultMessage: `Forme juridique de votre établissement` })}
        rows={1}
      />

      <TextField
        InputLabelProps={{ shrink: true }}
        className={classes.textField}
        defaultValue=""
        value={infos.location.rccm_number}
        onChange={(event) => handleValue(event, "rccm_number")}
        label={intl.formatMessage({ id: 'LOCATIONEDIT.LABEL_NUMRCCM', defaultMessage: `Numéro RCCM` })}
        rows={1}
      />

      {[0, 1, 2].map((cat) => (
        <div key={`category_${cat}`}>
          <FormControl className={classes.category} required>
            <InputLabel id={`category-select-label`}><FormattedMessage id="LOCATIONEDIT.INPUTLABEL_CATEG" defaultMessage="Catégorie" /></InputLabel>
            <Select
              labelId={`category-select-label`}
              id={`category-select`}
              value={
                (infos?.location?.categories &&
                  infos?.location?.categories[cat]?.id) ||
                0
              }
              onChange={(event) => handleCategory(event, cat)}
              disabled={
                infos?.location?.categories &&
                infos?.location?.categories.length < cat
              }
            >
              {selectableCategories.map((cat: any) => (
                <MenuItem key={cat.id} value={cat.id}>
                  {cat.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      ))}

      <TextField
        InputLabelProps={{ shrink: true }}
        className={classes.textField}
        defaultValue=""
        value={infos.location.category_suggestion}
        onChange={(event) => handleValue(event, "category_suggestion")}
        label={intl.formatMessage({ id: "LOCATIONSEDIT.LABEL_SUGESSCATEG", defaultMessage: "Suggérer une nouvelle catégorie" })}
        rows={1}
      />

      <div className={classes.mapContainer}>
        <div className={classes.mapTypo}>
          <Typography color="textSecondary"><FormattedMessage id="LOCATIONSEDIT.ENT_ADRESSE" defaultMessage="Adresse" /></Typography>
          <Typography color="textSecondary" variant="caption">
            <FormattedMessage
              id="LOCATIONEDIT.PLACER_ETABLISS_CARTE"
              defaultMessage="Cliquer sur la carte pour placer votre établissement" />
          </Typography>
        </div>

        <GMap infos={infos} setInfos={setInfos} />
      </div>
      {infos?.location?.address && (
        <Typography>{infos.location.address}</Typography>
      )}

      <div className={classes.space}></div>
      <TextField
        InputLabelProps={{ shrink: true }}
        className={classes.textField}
        defaultValue=""
        value={infos.location.hours}
        onChange={(event) => handleValue(event, "hours")}
        label={intl.formatMessage({ id: 'LOCATIONSEDIT.LABEL_HORAIRE', defaultMessage: "Horaires" })}
      />
      <TextField
        InputLabelProps={{ shrink: true }}
        className={classes.textField}
        defaultValue=""
        value={infos.location.phone}
        onChange={(event) => handleValue(event, "phone")}
        label={intl.formatMessage({ id: "LOCATIONSEDIT.LABEL_TELEPHON", defaultMessage: "Téléphone" })}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">{`+${appConfig.phonePrefix}`}</InputAdornment>
          ),
        }}
      />
      <TextField
        InputLabelProps={{ shrink: true }}
        className={classes.textField}
        defaultValue=""
        value={infos.location.email}
        onChange={(event) => handleValue(event, "email")}
        label={intl.formatMessage({ id: "LOCATIONSEDIT.LABEL_MAIL", defaultMessage: "Email" })}
        onBlur={(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => checkField()}
        error={errors.email ? true : false}
        helperText={errors.email && errors.email[0]}
      />

      <TextField
        InputLabelProps={{ shrink: true }}
        className={classes.textField}
        defaultValue=""
        value={infos.location.website}
        onChange={(event) => handleValue(event, "website")}
        label={intl.formatMessage({ id: 'LOCATIONSEDIT.LABEL_SITEWEB', defaultMessage: "Site web" })}
        type="url"
        onBlur={(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => checkField()}
        error={errors.website ? true : false}
        helperText={errors.website && errors.website[0]}
      />

      <TextField
        InputLabelProps={{ shrink: true }}
        className={classes.textField}
        defaultValue=""
        value={infos.location.facebook}
        onChange={(event) => handleValue(event, "facebook")}
        label={intl.formatMessage({ id: 'LOCATIONSEDIT.LABEL_FACEBOOK', defaultMessage: "Page facebook" })}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">facebook.com/</InputAdornment>
          ),
        }}
      />
    </form>
  );
};

export default LocationsEdit;
