import React from 'react'
import { Breadcrumbs, Link, Typography, useMediaQuery } from '@material-ui/core'
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import PrevNextButton from './PrevNextButton';
import { useTitle } from '../../utils/useTitle';
import { RouteStateI } from '../../types';
import clsx from 'clsx';
import{FormattedMessage} from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    button: {
      marginTop: theme.spacing(2)
    },
    instructions: {
      textAlign: "center",
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(1),
    },
    breadcrumbs: {
      marginLeft: theme.spacing(3)
    },
    breadcrumbsSm: {
      marginLeft: 0

    }
  })
);

type Props = {
  name?: string
  children: any
  disabled?:boolean
}


export default function ConnectedLayout({ children, name, disabled=false }: Props) {
  const { title, navTitle } = useTitle()
  const classes = useStyles();
  const { state } = useLocation<RouteStateI>();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <div>
        <Breadcrumbs className={clsx(classes.breadcrumbs, sm && classes.breadcrumbsSm)} aria-label="breadcrumb">
          <Link color="inherit" to="/"
            component={RouterLink}
          >
            <FormattedMessage
            id="CONNECTEDLAYOUT.TITRE_MON_ESPACE"
            defaultMessage="Mon espace"/>
          </Link>
          {name && <div>
            {name}
          </div>}
          {state?.status === "newLocation" && <div>
            <FormattedMessage
            id="CONNECTEDLAYOUT.NOUVELETABLISS"
            defaultMessage="Nouvel établissement"/>
          </div>}
          {state?.name && <Link
          color="inherit"
          to={`location/${state.locationId}`}
          component={RouterLink}
          >
            {state.name}
          </Link>}
          {state?.status === "upgrade" && <div>
          Mise à niveau
          </div>}
          {state?.status === "renewal" && <div>
          Renouvellement
          </div>}
          {navTitle && <div
            color="inherit"
            // onClick={handleClick}
            aria-current="page"
          >
            {navTitle}
          </div>}
        </Breadcrumbs>
      </div>

      <Typography variant="h5" className={classes.instructions}>{name ? name : title}</Typography>


      { children }

  { !name && <PrevNextButton disabled={disabled}/> }
    </>
  )
}
