import React, { useContext, useEffect } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import RegistrationContext from '../../registrationContext'
import Plan from "./Plan"
import { useLocation } from 'react-router-dom'
import { DynamicPlanI, RouteStateI } from '../../types'
import { usePlans } from '../../utils/usePlan'
import { useQuery } from 'react-query'
import { useUserContext } from '../../authentication'
import { fetchList } from '../../API'


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
  }))


  const Plans = ({ setDisabled }: { setDisabled: (b: boolean) => void }) => {
  const { infos, setInfos } = useContext(RegistrationContext)
  const { state } = useLocation<RouteStateI>()
  const {accessToken} = useUserContext();

  useEffect(() => {
    if (infos.plan) setDisabled(false)
  },[])

  const choosePlan = (plan: DynamicPlanI) => {
    // on clean les dates de la sponso au cas où l'utilisateur aurait commencé à choisir un plan et une sponso et serait revenu au plan bronze
    if (infos.location.sponsored_end_at) delete infos.location.sponsored_end_at;
    if (infos.location.sponsored_start_at) delete infos.location.sponsored_start_at;
    // on ajoute le plan selectionné
    setInfos({ ...infos, plan})
    setDisabled(false)
  }

  const deletePlan = () => {
    setInfos({ ...infos, plan: null });
    setDisabled(true)
  };

  const { data, status, } = useQuery<{
    results: DynamicPlanI[];
    pageCount: number;
    total: number;
  }>(
    ["plans"],
    () => fetchList("plans", {}, accessToken),
  );

  const plans = data?.results
  
    
  return (
    <div>
      {plans?.sort((a,b) => a.price_cents - b.price_cents)
        ?.filter((plan, i) => (!((state?.status === "upgrade") && i === 0) ||(state?.plan === plan.printed_name)))
        ?.map((plan, i) => (
          <Plan
            key={plan.id}
            deletePlan={deletePlan}
            selectedPlan={infos.plan}
            choosePlan={choosePlan}
            // title="Bronze"
            plan={plan}
          />
        ))}
    </div>
  );
};

export default Plans;
