// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase/app";

// Add the Firebase products that you want to use
import "firebase/firestore";
import 'firebase/storage';

import { useState, useEffect } from "react";

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyA_g2Iw-Ix6jzwKlgu6za524GxetztmIPg",
  authDomain: "mtn-yello-business.firebaseapp.com",
  databaseURL: "https://mtn-yello-business.firebaseio.com",
  projectId: "mtn-yello-business",
  storageBucket: "mtn-yello-business.appspot.com",
  messagingSenderId: "1057593631632",
  appId: "1:1057593631632:web:f9c0dc5b43450582e40915",
  measurementId: "G-BFQ1B0M0RG"
 };

firebase.initializeApp(firebaseConfig);

// export const { firestore } = firebase;

const storageRef = firebase.storage().ref();

export const useImage = (name: string) => {
  const [image, setImage] = useState<string>("");
  useEffect(() => {
    if (name && name.length > 0) {
      const getImage = async () => {
        try {
          const storedImage = await storageRef.child(name).getDownloadURL();
          setImage(storedImage);
        } catch { }
      };
      getImage();
    }
  }, [name]);
  return image;
};
