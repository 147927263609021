import React, { useContext, useEffect } from "react";
import { Typography } from "@material-ui/core";
import { useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { findSponsoType } from "../utils/findSponsoType";
import RegistrationContext from "../registrationContext";
import { RouteStateI } from "../types";
import { useLocation, useHistory, Prompt } from "react-router-dom";
import MainLayout from "./MainLayout";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useQueryClient } from "react-query";
import CustomButton from "./shared/CustomButton";
import appConfig from "../appConfig";
import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    field: {
      fontSize: 18,
    },
    text: {
      fontSize: 18,
      fontWeight: 600,
    },
    headline: {
      fontWeight: "normal",
      fontSize: 20,
      color: "#718096",
    },
    textContainer: {
      marginBottom: theme.spacing(1),
    },
    container: {
      marginBottom: theme.spacing(5),
      marginTop: theme.spacing(5),
      maxWidth: 600,
      margin: "auto",
    },
    paddingTop: {
      paddingTop: theme.spacing(2),
    },
    button: {
      marginTop: theme.spacing(2),
      width: "100%",
    },
  })
);

type Props = {
  categories?: any[];
};

const Resume = ({ categories }: Props) => {
  const intl = useIntl();

  const classes = useStyles();
  const { infos } = useContext(RegistrationContext);
  const { state } = useLocation<RouteStateI>();
  const history = useHistory();
  const path = useLocation();
  const sponsoType = findSponsoType(
    infos.location.sponsored_end_at,
    infos.location.sponsored_start_at
  );
  const [isBlocking, setIsBlocking] = useState(true);

  // find the names of the choosen categories
  const [categoriesNames, setCategoriesNames] = useState<any[]>([]);
  useEffect(() => {
    if (infos?.location?.category_ids && categories) {
      const newCategoriesName = infos.location.category_ids
        .map((categoryId) => {
          const name = categories.find(
            (category) => categoryId === category.id
          );
          return name;
        })
        .filter((cat: any) => cat !== undefined)
        .map((cat) => cat.name);
      setCategoriesNames([...newCategoriesName]);
    }
  }, [categories, infos]);

  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);
  const returnToMySweetHome = async () => {
    setIsBlocking(false);
    setIsLoading(true);
    setTimeout(() => {
      history.push("/", { prevPath: path });
    }, 1000);
    // await queryClient.invalidateQueries("users");
    // await queryClient.refetchQueries();
  };

  return (
    <MainLayout>
      <Prompt
        when={isBlocking}
        message={intl.formatMessage({
          id: "RESUME.MSG_BON_ENREGIST",
          defaultMessage:
            "Votre établissement a bien été enregistré, en retournant en arrière vous risquez de re-soumettre les données, voulez-vous tout de même continuer ?",
        })}
      />

      <div className={classes.container}>
        <Typography variant="h3">
          <FormattedMessage
            id="RESUME.TEXTE_FELICITATION"
            defaultMessage="Félicitation !"
          />
        </Typography>
        <br />
        {
          <>
            <Typography className={classes.headline}>

              <FormattedMessage
                id="RESUME.TEXT_RECAP"
                defaultMessage="Votre demande de souscription à l’offre {printedName} de l'annuaire {appName} {sponsoType, select,
                  0 {et votre demande de sponsorisation ont été adressées}
                  other {a été adressée}
                }"
                values={{appName: appConfig.appName, printedName: infos.plan?.printed_name, sponsoType: sponsoType.toString()}}
              />
              
            </Typography>
          </>
        }

        <br />

        {infos.location.name && (
          <>
            <Typography variant="h5">
              <FormattedMessage
                id="RESUME.VOTREETABLISS"
                defaultMessage="Votre établissement"
              />
            </Typography>
            <br />

            {infos?.location?.name && (
              <div className={classes.textContainer}>
                <Typography className={classes.field}>
                  <FormattedMessage
                    id="RESUME.NOM_ETABLISS_1"
                    defaultMessage="Nom de l'établissement:"
                  />
                </Typography>
                <Typography className={classes.text}>
                  {" "}
                  {infos.location.name}
                </Typography>
              </div>
            )}

            {!infos?.location?.name && state?.name && (
              <div className={classes.textContainer}>
                <Typography className={classes.field}>
                  <FormattedMessage
                    id="RESUME.NOMETABLISS_2"
                    defaultMessage="Nom de l'établissement:"
                  />
                </Typography>
                <Typography className={classes.text}> {state.name}</Typography>
              </div>
            )}

            {infos?.location?.description && (
              <div className={classes.textContainer}>
                <Typography className={classes.field}>
                  <FormattedMessage
                    id="RESUME.DESCRIP_COURTE"
                    defaultMessage="Description courte:"
                  />
                </Typography>
                <Typography className={classes.text}>
                  {" "}
                  {infos.location.short_description}
                </Typography>
              </div>
            )}

            {infos?.location?.description && (
              <div className={classes.textContainer}>
                <Typography className={classes.field}>
                  <FormattedMessage
                    id="RESUME.DESCRIPT"
                    defaultMessage="Description:"
                  />
                </Typography>
                <Typography className={classes.text}>
                  {" "}
                  {infos.location.description}
                </Typography>
              </div>
            )}

            {infos?.location?.legal_form && (
              <div className={classes.textContainer}>
                <Typography className={classes.field}>
                  <FormattedMessage
                    id="RESUME.TEXT_FORMJURID"
                    defaultMessage="Forme juridique:"
                  />
                </Typography>
                <Typography className={classes.text}>
                  {" "}
                  {infos.location.legal_form}
                </Typography>
              </div>
            )}

            {infos?.location?.rccm_number && (
              <div className={classes.textContainer}>
                <Typography className={classes.field}>
                  <FormattedMessage
                    id="RESUME.NUM_RCCM"
                    defaultMessage="Numéro RCCM:"
                  />
                </Typography>
                <Typography className={classes.text}>
                  {" "}
                  {infos.location.rccm_number}
                </Typography>
              </div>
            )}

            {categoriesNames?.length !== 0 &&
              categoriesNames.map((categoryName, i) => (
                <div className={classes.textContainer}>
                  <Typography className={classes.field}>
                    {intl.formatMessage(
                      {
                        id: "RESUME.CATEG_NO",
                        defaultMessage: `Catégorie n°:{number}`,
                      },
                      { number: i + 1 }
                    )}
                  </Typography>
                  <Typography className={classes.text}>
                    {" "}
                    {categoryName}
                  </Typography>
                </div>
              ))}

            {infos?.location?.address && (
              <div className={classes.textContainer}>
                <Typography className={classes.field}>
                  <FormattedMessage
                    id="RESUME.TEXT_ADRESSE"
                    defaultMessage="Adresse: "
                  />
                </Typography>
                <Typography className={classes.text}>
                  {" "}
                  {infos?.location?.address}
                </Typography>
              </div>
            )}

            {infos?.location?.hours && (
              <div className={classes.textContainer}>
                <Typography className={classes.field}>
                  <FormattedMessage
                    id="RESUME.TEXT_OUVERTUR"
                    defaultMessage="Ouverture: "
                  />
                </Typography>
                <Typography className={classes.text}>
                  {infos?.location?.hours}
                </Typography>
              </div>
            )}

            {infos?.location?.phone && (
              <div className={classes.textContainer}>
                <Typography className={classes.field}>Téléphone: </Typography>
                <Typography className={classes.text}>
                  {" "}
                  {infos?.location?.phone}
                </Typography>
              </div>
            )}

            {infos?.location?.email && (
              <div className={classes.textContainer}>
                <Typography className={classes.field}>Email: </Typography>
                <Typography className={classes.text}>
                  {" "}
                  {infos?.location?.email}
                </Typography>
              </div>
            )}

            {infos?.location?.website && (
              <div className={classes.textContainer}>
                <Typography className={classes.field}>
                  <FormattedMessage
                    id="RESUME.TEXT_WEBSITE"
                    defaultMessage="Site web: "
                  />
                </Typography>
                <Typography className={classes.text}>
                  {" "}
                  {infos?.location?.website}
                </Typography>
              </div>
            )}

            {infos?.location?.facebook && (
              <div className={classes.textContainer}>
                <Typography className={classes.field}>Facebook: </Typography>
                <Typography className={classes.text}>
                  {" "}
                  {infos?.location?.facebook}
                </Typography>
              </div>
            )}
          </>
        )}

        {infos?.plan && (
          <>
            <br />
            <Typography variant="h5">
              <FormattedMessage
                id="RESUME.TEXT_SOUSCRIP"
                defaultMessage="Votre Souscription"
              />
            </Typography>
            <br />

            {infos?.plan && (
              <div className={classes.textContainer}>
                <Typography className={classes.field}>Plan: </Typography>
                <Typography className={classes.text}>
                  {" "}
                  {infos?.plan?.printed_name}
                </Typography>
              </div>
            )}
            {infos?.location?.sponsored_end_at &&
              infos?.location?.sponsored_start_at && (
                <div className={classes.textContainer}>
                  <Typography className={classes.field}>
                    <FormattedMessage
                      id="RESUME.TEXTE_SPONSOR"
                      defaultMessage="Sponsorisation:"
                    />{" "}
                  </Typography>
                  <Typography className={classes.text}>
                    {" "}
                    {sponsoType?.toString()} jours
                  </Typography>
                </div>
              )}
          </>
        )}

        {/* {infos?.user?.name && <div className={classes.textContainer}><Typography className={classes.field}>
          Nom: </Typography><Typography className={classes.text}> {infos?.user?.name}
          </Typography></div>}
        {infos?.user?.phone && <div className={classes.textContainer}><Typography className={classes.field}>
          Téléphone: </Typography><Typography className={classes.text}> {infos?.user?.phone}
          </Typography></div>}
        {infos?.user?.email && <div className={classes.textContainer}><Typography className={classes.field}>
          Email: </Typography><Typography className={classes.text}> {infos?.user?.email}
          </Typography></div>} */}

        <br />

        <Typography className={classes.text}>
          <FormattedMessage
            id="RESUME.TEXT_ACCES1"
            defaultMessage="Vous pouvez maintenant accéder à votre espace client directement
          depuis l'application "
          />
          {appConfig.appName}. <br />
          <FormattedMessage
            id="RESUME.TEXT_ACCES2"
            defaultMessage="Pour vous connecter à votre compte, ciquez sur les 3 petits points"
          />{" "}
          <MoreVertIcon />{" "}
          <FormattedMessage
            id="RESUME.TEXT_ACCES3"
            defaultMessage="en haut à droit de l'application."
          />
        </Typography>

        <div className={classes.paddingTop}>
          <CustomButton
            className={classes.button}
            accent="main"
            submitFn={() => returnToMySweetHome()}
            isLoading={isLoading}
          >
            <FormattedMessage
              id="RESUME.BTN_ESPACE_CLIENT"
              defaultMessage="Mon Espace Client"
            />
          </CustomButton>
        </div>
        {/* <Typography className={classes.field}>
          À bientôt <span className={classes.field} role="img" aria-label="hand-emoji">👋</span>
        </Typography> */}
      </div>
    </MainLayout>
  );
};

export default Resume;
