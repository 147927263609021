import React, { useState } from "react";
import LaserPay from "./LaserPay";
import PaymentAction from "./PaymentAction";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import { PaymentI } from "../../types";
import MomoPay from "./MomoPay";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordion: {
      border: `1px solid ${theme.palette.divider}`,
      "&:not(:last-child)": {
        borderBottom: 0,
      },
      "&:before": {
        display: "none",
      },
    },
  })
);

function PaymentMethod({
  method,
  payment,
  onChange,
  expanded
}: {
  method: "laserpay" | "default" | "momopay";
  payment: PaymentI;
  expanded: boolean;
  onChange: (event:any, expanded:boolean) => void;
}) {
  const intl = useIntl()
  const classes = useStyles();

  const { title, Component } = {
    laserpay: {
      title: "Payer via LaserPay",
      Component: LaserPay,
    },
    default: {
      title: intl.formatMessage({id:"PAYMENT_METHOD.CHOOSE_DEFAULT", defaultMessage:"Payer manuellement"}),
      Component: PaymentAction,
    },
    momopay: {
      title: intl.formatMessage({id:"PAYMENT_METHOD.CHOOSE_MOMOPAY", defaultMessage:"Payer via MomoPay"}),
      Component: MomoPay,
    }
  }[method];

  return (
    <Accordion
      style={{ margin: 0 }}
      elevation={0}
      square
      className={classes.accordion}
      expanded={expanded}
      onChange={onChange}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${method}_controls`}
        id={`${method}_header`}
      >
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Component payment={payment} />
      </AccordionDetails>
    </Accordion>
  );
}

export default PaymentMethod;
