import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";


const useQueryPath = () => {
    return new URLSearchParams(useLocation().search);
}

export const useQueryFromPath = (queryName: string) => {
  const queryFromPath = useQueryPath();
  const [query, setQuery] = useState<any>()
  useEffect(() => {
    setQuery(queryFromPath.get(queryName))
  }, [])

  return query


}