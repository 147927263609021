import React from 'react'
import { Button, CircularProgress } from '@material-ui/core'
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loader: {
      // marginLeft: theme.spacing(3),
      position: "absolute"
    },
    button: {
      marginTop: theme.spacing(2),
      width: "100%",
    },
    relative: {
      position: "relative"
    }
  })
);


type Props = {
  children: any
  isLoading?: boolean
  submitFn?: () => void
  accent?: "main"
  disabled?: boolean
  className?: string | undefined
}

const CustomButton = ({ disabled, accent, children, isLoading, submitFn, className }: Props) => {
  const classes = useStyles()

  return (
    <Button
      className={clsx(classes.relative, className ? className : classes.button)}
      variant="contained"
      color={accent === "main" ? "secondary" : "default"}
      onKeyPress={submitFn}
      onClick={submitFn}
      disabled={disabled || isLoading}
    >
      {isLoading ? (
        <>
          {children}
          {" "}
          <CircularProgress className={classes.loader} size={20} />
        </>
      ) : (
        <>{children}</>
      )}
    </Button>
  )
}

export default CustomButton
