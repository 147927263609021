import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { fetchList } from "../../API";
import { ProductI, Salesperson } from "../../types";
import { useUserContext } from "../../authentication";
import { actionate } from "../../API";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import {
  Button,
  Checkbox,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { FormattedMessage, useIntl } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    field: {
      marginLeft: theme.spacing(2),
    },
    checkbox: {
      display: "flex",
      alignItems: "center",
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },

    submitBtn: {
      marginLeft: theme.spacing(2),
    },
    alert: {
      marginTop: theme.spacing(2),
      maxWidth: 600,
      marginRight: "auto",
      marginLeft: "auto",
    },
  })
);

type Props = {
  toBePayed: ProductI[];
};

function SalespeopleSelector({ toBePayed }: Props) {
  const intl = useIntl();

  const classes = useStyles();

  const authentication = useUserContext();
  // Si plusieurs produits, on prend le commercial du premier
  const initialSalesperson = toBePayed[0].salesperson?.id;
  const [salesperson, setSalesperson] = useState(initialSalesperson || "none");
  const [isChecked, setIsChecked] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  useEffect(() => {
    if (successAlert == true) {
      setTimeout(() => {
        setSuccessAlert(false);
      }, 3000);
    }
  }, [successAlert]);

  ///// Fetch des commerciaux
  const fetchObject = {};
  const { data } = useQuery<{
    results: Salesperson[];
  }>(
    ["salespeople", fetchObject],
    () => fetchList("salespeople", fetchObject, authentication.accessToken),
    {
      keepPreviousData: true,
      onError: (e: any) => {
        authentication.logOut();
      },
    }
  );

  const mutation = useMutation({
    mutationFn: (product: ProductI) => {
      return actionate({
        action: "update_salesperson",
        model: "products",
        id: product.id,
        params:{
          salesperson_id: salesperson
        },
        method: "PATCH",
        accessToken: authentication.accessToken || "",
      });
    },
    onSuccess: () => {
      setSuccessAlert(true);
    },
  });

  const submitSalesperson = async () => {
    // On set l'état et envoie la donnée en même temps
    setSalesperson(salesperson);
    if (salesperson !== "none") {
      toBePayed &&
        toBePayed.forEach((tbp) => {
          mutation.mutate(tbp);
        });
    }
  };

  return (
    <div>
      <div className={classes.checkbox}>
        <Checkbox
          checked={isChecked}
          inputProps={{ "aria-label": "uncontrolled-checkbox" }}
          onChange={() => setIsChecked(!isChecked)}
        />
        <Typography className={classes.field}>
          <FormattedMessage
            id="PAYMENTACTION.MSG_SELECT_CONTACT_COMMERCIAL"
            defaultMessage="Souhaitez-vous sélectionner un contact commercial ?"
          />
        </Typography>
      </div>

      {isChecked && (
        <>
          <div className={classes.field}>
            <InputLabel id="commercial-select-label">
              <FormattedMessage
                id="PAYMENTACTION.INPUTLABEL_COMMERCIAL"
                defaultMessage="Commercial"
              />
            </InputLabel>
            <Select
              labelId="commercial-select-label"
              id="commercial-simple-select"
              value={salesperson}
              label={intl.formatMessage({
                id: "PAYMENTACTION.SELECT_LAB_COMMERCIAL",
                defaultMessage: "Commercial",
              })}
              onChange={(event) =>
                setSalesperson(event.target?.value as string)
              }
            >
              <MenuItem value="none" key="none">
                <FormattedMessage
                  id="PAYMENTACTION.MENUITEM_AUCUN"
                  defaultMessage="Aucun"
                />
              </MenuItem>
              {data?.results.map((sPerson) => (
                <MenuItem value={sPerson.id} key={sPerson.id}>
                  {sPerson.last_name} {sPerson.first_name}
                </MenuItem>
              ))}
            </Select>
            <Button
              onClick={submitSalesperson}
              className={classes.submitBtn}
              variant="contained"
              color="secondary"
              size="small"
            >
              <FormattedMessage id="SALESPEOPLESELECTOR.BTN_ENREGIS" defaultMessage="Enregistrer"/>
            </Button>
            {successAlert && (
              <Alert className={classes.alert} severity="success">
                <FormattedMessage
                  id="PAYMENTACTION.MSG_MODIF_ENREG"
                  defaultMessage="Les modifications ont été enregistrées"
                />
              </Alert>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default SalespeopleSelector;
