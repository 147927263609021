import { useState, useEffect, useContext } from "react";
import appConfig from "./appConfig";
import React from "react";
import { User } from "./types";
import { createOne, fetchOne } from "./API";
import { useQuery } from "react-query";
import { useIntl } from "react-intl";

export const useAuth = () => {
  // strings liées au token (doublées dans localStorage)
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [userId, setUserId] = useState<string | null>(null);

  const isNotAuthorized = (error: any) => {
    if (
      error.status === 403 ||
      error.statusMessage === "Forbidden" ||
      error.statusMessage === "Unauthorized"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const query = useQuery(
    ["users", { id: userId, include: ["owned_locations"] }],
    () => {
      if (accessToken && userId) {
        return fetchOne(
          "users",
          { id: userId, include: ["owned_locations"] },
          accessToken
        );
      }
    },
    {
      retry: false,
      enabled: Boolean(userId && accessToken),
      onError: (e: any) => {
        isNotAuthorized(e) && logOut();
      },
    }
  );

  const user = query?.data?.result;
  const userStatus = query.status;

  const [initialized, setInitialized] = useState(false); // Sert pour le reloading, on attend d'être sûr que les valeurs soient prises

  useEffect(() => {
    // Au montage du composant, on récupère les éventuelles valeurs dans localStorage
    hydrateToken();
    setInitialized(true);
    // Danger, à terme stocker le jwt ailleurs
  }, []);

  const logOut = async () => {
    setUserId(null);
    setAccessToken(null);
    localStorage.removeItem("user_id");
    localStorage.removeItem("access_token");
  };

  const hydrateToken = () => {
    setAccessToken(localStorage.getItem("access_token"));
    setUserId(localStorage.getItem("user_id"));
  };

  // Méthode pour Set le token (et user_id), seule à utiliser pour synchro avec LS
  const writeToken = (access_token: string, user_id: string) => {
    localStorage.setItem("user_id", user_id);
    localStorage.setItem("access_token", access_token);
    setAccessToken(access_token);
    setUserId(user_id);
  };

  type createTokenI = {
    email?: string;
    password?: string;
    auto_login_token?: string;
  };

  const createToken = async ({
    email,
    password,
    auto_login_token,
  }: createTokenI) => {
    const attributes = auto_login_token
      ? {
          attributes: {
            auto_login_token: auto_login_token,
            expiration: "regular",
          },
        }
      : {
          attributes: {
            email: email,
            password: password,
            expiration: "regular",
          },
        };
    const response = await createOne("tokens", attributes);
    if (response.ok) {
      const authorization = (await response.json()) as {
        access_token: string;
        email: string;
        id: number;
        expiration: string;
      };
      writeToken(authorization.access_token, authorization.id.toString());
      return { ...authorization, user_id: authorization.id.toString() };
    } else {
      return { error: response.statusText };
    }
  };

  return {
    user,
    accessToken,
    logIn: createToken,
    logOut,
    initialized,
    userStatus,
  };
};

export type UserContextType = {
  user: User | null;
  // token: string | null;
  // setToken: ((token: string) => void) | null;
  // setUser: React.Dispatch<any>;
  accessToken: string | null;
  logIn: ({
    email,
    password,
    auto_login_token,
  }: any) => Promise<
    | { access_token: string; email: string; id: number; expiration: string }
    | { error: string }
  >;
  initialized: boolean;
  logOut: () => void;
  userStatus: string;
};
export const UserContext = React.createContext<UserContextType | null>(null);

export const useUserContext = () => {
  const ctx = useContext(UserContext);
  if (!ctx) {
    throw new Error(
      "Erreur : n'utilisez le contexte d'utilisateur qu'au sein d'un provider d'utilisateur"
    );
  }
  return ctx;
};
