import zamaniLogo from "./assets/zamani-logo.png";
import mtnLogo from "./assets/MTN_2022_Logo_Black_RGB.svg";
import mntFavicon from "./assets/mtn-favicon.png";
import laserLogo from "./assets/laser-logo.png";

const base = {
  appName: "Laser App",
  locale: "fr",
  colors: {
    primary: "#222222", // bcp de trucs par défaut (indicateurs, menus, liens) + quelques trucs à nous (indicateur de tab)
    navBarBg: "#222222",
    navBarFont: "#999999",
    btnBg: "#337aff",
    btnFont: "#ffffff",
    tabFont: "#337aff",
    accent: "#337aff",
  },
  brand: {
    name: "Lasercats",
    logo: laserLogo,
    favicon: laserLogo,
  },
  fonts: {
    familyText: "Avenir Light",
    familyHeading: "Avenir Black",
  },
  sections: {
    services: "Services",
    offers: "Deals",
    news: "Actu",
    directory: "Magasins",
  },
  categories_order: ["directory", "offers", "services", "stories"],
  actionCable: `wss://${
    process.env.REACT_APP_API_URL?.split("://")[1] ||
    "selfcare-backend-base.herokuapp.com"
  }/cable`,
  webhook_endpoint: `${
    process.env.REACT_APP_API_URL ||
    "https://selfcare-backend-base.herokuapp.com"
  }/webhooks`,
  API: `${
    process.env.REACT_APP_API_URL ||
    "https://selfcare-backend-base.herokuapp.com"
  }/api/v2`,
  website_url: "https://selfcare-website-base.herokuapp.com/",
  googlePlacesKey: "AIzaSyB0QdBsX3GcGZWNUHtasud9dGux_KSVTMw",
  countryCode: "fr",
  capitalPosition: { lat: 6.36, lng: 2.4 },
  users: true,
  phonePrefix: "33",
  currency: "XOF",
  sponsorship_options: {
    2: 6,
    4: 1000,
  },

  firebaseConfig: {
    apiKey: "AIzaSyC2WlPGr9cW6fiCiA_91gVDCFYWgV4Jr9U",
    authDomain: "selfcare-dev-4f0c2.firebaseapp.com",
    projectId: "selfcare-dev-4f0c2",
    storageBucket: "selfcare-dev-4f0c2.appspot.com",
    messagingSenderId: "240327210023",
    appId: "1:240327210023:web:ac432ceb7f44594adc4a02",
    measurementId: "G-FDQCVLW81F",
  },
};

const zamani = {
  appName: "Zam Store",
  locale: "fr",
  colors: {
    primary: "#337aff", // bcp de trucs par défaut (indicateurs, menus, liens) + quelques trucs à nous (indicateur de tab)
    navBarBg: "#fff517",
    navBarFont: "#De00ff",
    btnBg: "#337aff",
    btnFont: "#ffffff",
    tabFont: "#337aff",
    accent: "#337aff",
  },
  brand: {
    name: "Zamani Telecom",
    logo: zamaniLogo,
    favicon: zamaniLogo,
  },
  fonts: {
    familyText: "Avenir Light",
    familyHeading: "Avenir Black",
  },
  sections: {
    services: "Services",
    offers: "Bons Plans",
    news: "Actualités",
    directory: "Annuaire",
  },
  // categories_order: ['offers', 'services', 'stories', 'directory'],
  actionCable: `wss://${
    process.env.REACT_APP_API_URL?.split("://")[1] ||
    "selfcare-backend-zamani.herokuapp.com"
  }/cable`,
  webhook_endpoint: `${
    process.env.REACT_APP_API_URL ||
    "https://selfcare-backend-zamani.herokuapp.com"
  }/webhooks`,
  API: `${
    process.env.REACT_APP_API_URL ||
    "https://selfcare-backend-zamani.herokuapp.com"
  }/api/v2`,
  website_url: "https://zamani-selfcare.innovacontents.com/",
  googlePlacesKey: "AIzaSyB0QdBsX3GcGZWNUHtasud9dGux_KSVTMw",
  countryCode: "ne",
  capitalPosition: { lat: 13.51, long: 2.11 },
  users: false,
  phonePrefix: "227",
  currency: "XOF",
  sponsorship_options: {
    30: 15000,
    90: 30000,
  },
  payment_methods: ["default"],
  default_payment_wording: {
    introduction: "Veuillez compléter vos références de transaction",
    momo_transaction_id:
      "Veuillez renseigner votre référence de compte client commençant par 'PCXXXXXX'",
    momo_phone_used: "Veuillez renseigner le numéro de la facture",
  },
  firebaseConfig: {
    apiKey: "AIzaSyB7_W9Bly4KN_OO03QzNCgLE8-B-VzmoE8",
    authDomain: "selfcare-zamani.firebaseapp.com",
    projectId: "selfcare-zamani",
    storageBucket: "selfcare-zamani.appspot.com",
    messagingSenderId: "44642644510",
    appId: "1:44642644510:web:c48c519abe9ede38a7b40f",
    measurementId: "${config.measurementId}",
  },
};

const mtnBenin = {
  appName: "Yello Biz",
  locale: "fr",
  colors: {
    primary: "#FFCC00",
    navBarBg: "#FFCC00",
    navBarFont: "#292929",
    btnBg: "#FFCC00",
    btnFont: "#292929",
    tabFont: "#292929",
    accent: "#FFCC00",
  },
  brand: {
    name: "MTN Bénin",
    logo: mtnLogo,
    favicon: mntFavicon,
  },
  fonts: {
    familyText: "Avenir Light",
    familyHeading: "Avenir Black",
  },
  sections: {
    offers: "Bons Plans",
    services: "Services",
    news: "Actualités",
    directory: "Annuaire",
  },
  actionCable: `wss://${
    process.env.REACT_APP_API_URL?.split("://")[1] ||
    "mtn-selfcare-production.herokuapp.com"
  }/cable`,
  webhook_endpoint: `${
    process.env.REACT_APP_API_URL ||
    "https://mtn-selfcare-production.herokuapp.com"
  }/webhooks`,
  API: `${
    process.env.REACT_APP_API_URL ||
    "https://mtn-selfcare-production.herokuapp.com"
  }/api/v2`,
  // API: process.env.REACT_APP_API_URL === "base" ? "https://selfcare-backend-base.herokuapp.com/api/v2" : "https://mtn-selfcare-production.herokuapp.com/api/v2",
  website_url: "https://mtn-yellobiz-selfcare.innovacontents.com",
  googlePlacesKey: "AIzaSyCmET8VLakZLLT5XUa86P2XAtAyGGWMDUk",
  countryCode: "bj",
  capitalPosition: { lat: 6.36, lng: 2.4 },
  users: true,
  phonePrefix: "229",
  currency: "XOF",
  sponsorship_options: {
    30: 25000,
    90: 50000,
  },
  payment_methods: ["default", "momopay"],
  default_payment_wording: {
    introduction:
      "Le numéro MoMoPay a utiliser pour votre paiement est le 91401414",
    momo_transaction_id: "Code MomoPay",
    momo_phone_used: "N° de téléphone de la transaction",
  },
  momopay: {
    base_url: "https://proxy.momoapi.mtn.com/collection",
    api_key: "e8de7228f1764facb1754ad40d32ff61",
    api_user: "44205ab7-8ddd-4110-a141-19ad34acc722",
    callback_url:"https://mtn-yellobiz-selfcare.innovacontents.com",
    target_env: 'mtnbenin' ,
    ocp_qpim_subscription_key: 'c189b0fb4716480dbb11ccad40fa413a'
  },
  termsAndConditionsUrl: "https://www.innovacontents.com/cgu-annuaire-zamani",
  firebaseConfig: {
    apiKey: "AIzaSyA_g2Iw-Ix6jzwKlgu6za524GxetztmIPg",
    authDomain: "mtn-yello-business.firebaseapp.com",
    databaseURL: "https://mtn-yello-business.firebaseio.com",
    projectId: "mtn-yello-business",
    storageBucket: "mtn-yello-business.appspot.com",
    messagingSenderId: "1057593631632",
    appId: "1:1057593631632:web:f9c0dc5b43450582e40915",
    measurementId: "G-BFQ1B0M0RG",
  },
};

const mtnZambia = {
  appName: "Yello Biz Zambia",
  locale: "en",
  colors: {
    primary: "#FFCC00",
    navBarBg: "#FFCC00",
    navBarFont: "#292929",
    btnBg: "#FFCC00",
    btnFont: "#292929",
    tabFont: "#292929",
    accent: "#FFCC00",
  },
  brand: {
    name: "MTN Zambia",
    logo: mtnLogo,
    favicon: mntFavicon,
  },
  fonts: {
    familyText: "Avenir Light",
    familyHeading: "Avenir Black",
  },
  sections: {
    offers: "Offers",
    services: "Services",
    news: "News",
    directory: "Directory",
  },
  actionCable: `wss://${
    process.env.REACT_APP_API_URL?.split("://")[1] ||
    "selfcare-backend-zambia.herokuapp.com"
  }/cable`,
  webhook_endpoint: `${
    process.env.REACT_APP_API_URL ||
    "https://selfcare-backend-zambia.herokuapp.com"
  }/webhooks`,
  API: `${
    process.env.REACT_APP_API_URL ||
    "https://selfcare-backend-zambia.herokuapp.com"
  }/api/v2`,
  // API: process.env.REACT_APP_API_URL === "base" ? "https://selfcare-backend-base.herokuapp.com/api/v2" : "https://mtn-selfcare-production.herokuapp.com/api/v2",
  website_url: "https://mtn-zambia-selfcare.innovacontents.com/",
  googlePlacesKey: "AIzaSyCmET8VLakZLLT5XUa86P2XAtAyGGWMDUk",
  countryCode: "zm",
  capitalPosition: { lat: -15.34, lng: 28.42 },
  users: true,
  phonePrefix: "260",
  currency: "ZMW",
  sponsorship_options: {
    30: 150,
    90: 300,
  },
  payment_methods: ["default", "momopay"],
  default_payment_wording: {
    introduction: "MoMoPay number to use for your payment : 0967302074",
    momo_transaction_id: "MomoPay code",
    momo_phone_used: "phone number used for transaction",
  },
  momopay: {
    base_url: "https://proxy.momoapi.mtn.com/collection",
    api_key: "286d2e7ddafd4860b9a3b146b3e9ad66",
    api_user: "632f2d1b-eb06-4a91-ad04-4c47fcfee060",
    callback_url:"https://mtn-zambia-selfcare.innovacontents.com",
    target_env: 'mtnzambia',
    ocp_qpim_subscription_key: '97ee714d44984036a80dc1de8fece327'
  },
  termsAndConditionsUrl:
    "https://www.innovacontents.com/terms-and-conditions-mtn-zambia-business-di",
  firebaseConfig: {
    apiKey: "AIzaSyBQa6xfuHuBUhQPOOjN9RbJpWoaTC71hQQ",
    authDomain: "selfcare-zambie.firebaseapp.com",
    projectId: "selfcare-zambie",
    storageBucket: "selfcare-zambie.appspot.com",
    messagingSenderId: "943194431053",
    appId: "1:943194431053:web:e8028415db813df4970604",
    measurementId: "G-SL8MQN2XNG",
  },
};

const configuration = (): appConfigType => {
  switch (process.env.REACT_APP_CLIENT) {
    case "zamani":
      return zamani;
    case "mtn-benin":
      return mtnBenin;
    case "mtn-zambia":
      return mtnZambia;
    case "base":
      return base;
    default:
      return base;
  }
};
interface appConfigType {
  [key: string]: any;
  sections: {
    [key in "offers" | "news" | "services" | "directory"]: string;
  };
  sponsorship_options: {
    [key: number]: number;
  };
}

export default configuration();
