import React, { useEffect, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { InputAdornment, TextField, Typography } from "@material-ui/core";
import { useUserContext } from "../authentication";
import { updateOne } from "../API";
import Alert from "@material-ui/lab/Alert";
import MainLayout from "./MainLayout";
import { useMutation, useQueryClient } from "react-query";
import { useQueryFromPath } from "../utils/useQueryFromPath";
import CustomButton from './shared/CustomButton';
import { useHistory } from 'react-router'
import appConfig from "../appConfig";
import {FormattedMessage} from "react-intl";
import {useIntl} from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
    paper: {
      width: "70%",
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      paddingBottom: theme.spacing(4),
      paddingTop: theme.spacing(4),
      marginBottom: theme.spacing(6),
    },
    center: {
      paddingTop: theme.spacing(6),
      marginTop: theme.spacing(6),
      paddingLeft: theme.spacing(6),
    },
    container: {
      //   width: "80%",
      display: "flex",
      flexDirection: "column",
      marginBottom: theme.spacing(3),
      maxWidth: 600,
      margin: "auto",
    },
    textField: {
      marginTop: theme.spacing(2),
    },
    button: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
      display: "flex",
      justifyContent: "flex-end",
    },
    alert: {
      marginTop: theme.spacing(2),
    },
  })
);

function UserInfos() {
  const intl=useIntl();

  const classes = useStyles();
  const authentication = useUserContext();

  const [owner, setOwner] = useState({
    id: authentication?.user && authentication.user.id,
    name: authentication?.user && authentication.user.name,
    phone: authentication?.user && authentication.user.phone,
    email: authentication?.user && authentication.user.email,
    password: ""
  });

  const autoToken = useQueryFromPath("auto_login_token");

  useEffect(() => {
    let formatedPhone = authentication?.user && authentication.user.phone
    if (authentication?.user?.phone?.includes(`+${appConfig.phonePrefix}`)) {
      formatedPhone = authentication.user.phone.split(`+${appConfig.phonePrefix}`)[1]
    }
    setOwner({
      id: authentication?.user && authentication.user.id,
      name: authentication?.user && authentication.user.name,
      phone: formatedPhone,
      email: authentication?.user && authentication.user.email,
      password: ""
    })
  }, [authentication?.user])

  const handleValue = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    field: string
  ) => {
    switch (field) {
      case "name":
        setOwner({ ...owner, name: event.target.value });
        break;
      case "phone":
        setOwner({ ...owner, phone: event.target.value });
        break;
      case "email":
        setOwner({ ...owner, email: event.target.value });
        break;
      case "pwd":
        setOwner({ ...owner, password: event.target.value });
      default:
        break;
    }
  };

  const history = useHistory()

  const [isLoading, setIsLoading] = useState({ user: false, pwd: false })
  const [isUpdate, setIsUpdate] = useState(false);
  const [isPwdUpdate, setIsPwdUpdate] = useState(false);

  const mutation = useMutation((options: any) => updateOne("users", options, authentication.accessToken
  ))

  const updateOrCreateUser = async (action: "pwd" | "user") => {
    if (action === "user") {
      setIsLoading({ ...isLoading, user: true })
    } else {
      setIsLoading({ ...isLoading, pwd: true })
    }

    const attributes = action === "user" ? {
      name: action === "user" && owner.name,
      email: action === "user" && owner.email,
      phone: action === "user" && owner.phone?.includes(`+${appConfig.phonePrefix}`) ? owner.phone : `+${appConfig.phonePrefix}${owner.phone}`,
    } : {
      password: action === "pwd" && owner.password,
    }

    mutation.mutate({ attributes: attributes, id: owner.id, }, {
      onSuccess: (data, variables, context) => {
        if (action === "user") {
          setIsUpdate(true)
          setIsLoading({ ...isLoading, user: false })
        } else {
          setIsPwdUpdate(true);
          setIsLoading({ ...isLoading, pwd: false })
        }
        history.go(0)
        // await queryClient.invalidateQueries("users");
        // await queryClient.refetchQueries();
        setTimeout(() => {
          action === "user" && setIsUpdate(false);
          action === "pwd" && setIsPwdUpdate(false);

        }, 2500);
      },
      onError: (error, variables, context) => {
        // I will fire second!
      },
      onSettled: (data, error, variables, context) => {
        // I will fire second!
      }
    })

  };




  return (
    <MainLayout>
      <div>
        <div>
          <form className={classes.container}>
            <Typography
              className={classes.title}
              color="textSecondary"
              variant="h4"
            >
             <FormattedMessage
              id="USERINFOS.MSG_PAGESAISIE_INFO"
              defaultMessage="Editez vos informations"/>
            </Typography>

            <TextField
              InputLabelProps={{ shrink: true }}
              className={classes.textField}
              onChange={(event) => handleValue(event, "name")}
              label={intl.formatMessage({id:'USERINFOS.TEXTFIELD_LABEL_NOM',defaultMessage:"Nom"})}
              value={owner.name}
            />
            <TextField
              InputLabelProps={{ shrink: true }}
              className={classes.textField}
              required
              onChange={(event) => handleValue(event, "phone")}
              label={intl.formatMessage({id:'USERINFOS.TEXTFIELD_LABEL_TELEPH',defaultMessage:"Téléphone"})}
              value={owner.phone}
              InputProps={{
                startAdornment: <InputAdornment position="start">{`+${appConfig.phonePrefix}`}</InputAdornment>,
              }}
            />
            <TextField
              InputLabelProps={{ shrink: true }}
              className={classes.textField}
              required
              onChange={(event) => handleValue(event, "email")}
              label="Email"
              value={owner.email}
            />

            <div className={classes.button}>
              <CustomButton
                isLoading={isLoading.user}
                accent="main"
                submitFn={() => updateOrCreateUser("user")}
              >
                <FormattedMessage
                id="USERINFOS.BTN_METTREAJOUR"
                defaultMessage="Mettre à jour"/>
              </CustomButton>
            </div>
            {isUpdate && (
              <Alert className={classes.alert} severity="success">
                <FormattedMessage
                id="USERINFOS.CONFIRMATION_MISEAJOUR"
                defaultMessage="Vos informations ont bién été mises à jour."/>
              </Alert>
            )}
          </form>

          <form className={classes.container}>
            <Typography
              className={classes.title}
              color="textSecondary"
              variant="h4"
            >
              <FormattedMessage
              id="USERINFOS.TEXTE_REINIT_MDP"
              defaultMessage="Réinitialisez votre mot de passe"/>
            </Typography>

            <TextField
              InputLabelProps={{ shrink: true }}
              className={classes.textField}
              onChange={(event) => handleValue(event, "pwd")}
              label={intl.formatMessage({id:'USERINFOS.TEXTFIELD_LABEL_MDP',defaultMessage:"Mot de passe"})}
              value={owner.password}
            />
            {autoToken && (
              <Alert className={classes.alert} severity="warning">
                <FormattedMessage
                id="USERINFOS.TEXTE_REINIT_MDP2"
                defaultMessage="Veuillez réinitialiser votre mot de passe."/>
              </Alert>
            )}

            <div className={classes.button}>
              <CustomButton
                isLoading={isLoading.pwd}
                accent="main"
                submitFn={() => updateOrCreateUser("pwd")}
              >
                <FormattedMessage
                id="USERINFOS.BTN_REINIT"
                defaultMessage="Réinitialiser"/>
              </CustomButton>
            </div>
            {isPwdUpdate && (
              <Alert className={classes.alert} severity="success">
                <FormattedMessage
                id="USERINFOS.MSG_REINIT_MDP_OK"
                defaultMessage="Votre mot passe a été réinitialisé."/>
              </Alert>
            )}
          </form>
        </div>
      </div>
    </MainLayout>
  );
}

export default UserInfos;
